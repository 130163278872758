import { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import CircularProgress from '@mui/material/CircularProgress';
import { AuthContext } from "context";
import Tooltip from '@mui/material/Tooltip';

function OTPVerification() {
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const [otpError, setOtpError] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [timeLeft, setTimeLeft] = useState(300); // 5 minutes in seconds
  const navigate = useNavigate();
  const { setUserData } = useContext(AuthContext);

  useEffect(() => {
    if (timeLeft > 0) {
      const timerId = setTimeout(() => setTimeLeft(timeLeft - 1), 1000);
      return () => clearTimeout(timerId);
    }
  }, [timeLeft]);

  const handleChange = (element, index) => {
    if (/^\d*$/.test(element.value)) {
      const newOtp = [...otp];
      newOtp[index] = element.value;
      setOtp(newOtp);
      if (element.value && index < 5) {
        document.getElementById(`otp-${index + 1}`).focus();
      }
      setOtpError("");
      setResponseMessage("");
    }
  };

  const handleKeyDown = (event, index) => {
    if (event.key === "Backspace" && !otp[index] && index > 0) {
      document.getElementById(`otp-${index - 1}`).focus();
    }
  };

  const validateOtp = () => {
    const otpValue = otp.join("");
    if (!otpValue || otpValue.length < 6) {
      setOtpError("Please enter a 6-digit OTP.");
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    if (!validateOtp()) {
      return;
    }
    try {
      setIsLoading(true);
      setResponseMessage("");
      const email = localStorage.getItem("tempEmail");
      if (!email) {
        throw new Error("Email not found. Please login again.");
      }
      const otpValue = otp.join("");
      const response = await fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/verify-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ otp: otpValue, email }),
      });
      const data = await response.json();
      if (data.success) {
        setResponseMessage("OTP verified successfully");
        localStorage.setItem("token", data.token);
        localStorage.setItem("role", data.role);
        setUserData({ token: data.token, role: data.role });
        localStorage.removeItem("tempEmail");
        navigate("/patients");
      } else {
        setResponseMessage(data.message);
        setOtp(new Array(6).fill(""));
      }
    } catch (error) {
      setResponseMessage(error.message || "An error occurred. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleResend = async () => {
    try {
      setIsLoading(true);
      const email = localStorage.getItem("tempEmail");
      if (!email) {
        throw new Error("Email not found. Please login again.");
      }
      const response = await fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/resend-otp`, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      if (data.success) {
        setResponseMessage("New OTP sent successfully");
        setTimeLeft(300); // Reset timer
        setOtp(new Array(6).fill("")); // Clear OTP input fields
      } else {
        setResponseMessage(data.message);
      }
    } catch (error) {
      setResponseMessage(error.message || "Failed to resend OTP. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Verify OTP
          </MDTypography>
        </MDBox>
        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form">
            <MDBox mb={2} display="flex" justifyContent="space-between">
              {otp.map((digit, index) => (
                <MDInput
                  key={index}
                  id={`otp-${index}`}
                  type="text"
                  value={digit}
                  onChange={(e) => handleChange(e.target, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: 'center', width: '40px' },
                  }}
                />
              ))}
            </MDBox>
            {otpError && (
              <MDBox mb={2}>
                <MDTypography variant="caption" color="error">
                  {otpError}
                </MDTypography>
              </MDBox>
            )}
            <MDBox mt={2} mb={1}>
              <MDTypography variant="body2">
                Time remaining: {Math.floor(timeLeft / 60)}:{(timeLeft % 60).toString().padStart(2, '0')}
              </MDTypography>
            </MDBox>
            <MDBox mt={4} mb={1}>
              <MDButton
                variant="gradient"
                color="info"
                fullWidth
                onClick={handleSubmit}
                disabled={isLoading || Boolean(otpError) || timeLeft === 0}
              >
                {isLoading ?
                  <CircularProgress size={24} style={{ color: 'white' }} />
                  : "Verify OTP"}
              </MDButton>
            </MDBox>
            <MDBox mt={2}>
              <Tooltip title={timeLeft > 0 ? "You can resend OTP after the current one expires" : ""}>
                <span>
                  <MDButton
                    variant="outlined"
                    color="info"
                    fullWidth
                    onClick={handleResend}
                    disabled={isLoading || timeLeft > 0}
                  >
                    Resend OTP
                  </MDButton>
                </span>
              </Tooltip>
            </MDBox>
            {responseMessage && (
              <MDBox mt={2}>
                <MDTypography
                  variant="body2"
                  color={responseMessage.includes("successfully") ? "success" : "error"}
                >
                  {responseMessage}
                </MDTypography>
              </MDBox>
            )}
          </MDBox>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default OTPVerification;