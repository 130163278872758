import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import Card from '@mui/material/Card';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from 'components/MDButton';
import CircularProgress from '@mui/material/CircularProgress';
import toast from 'react-hot-toast';

function AdminApproval() {
    const { token } = useParams();           
    const navigate = useNavigate();
    const [isAcceptLoad, setIsAcceptLoad] = useState(false);
    const [isRejectLoad, setIsRejectLoad] = useState(false);
    const [responseMessage, setResponseMessage] = useState('');
    const [physicianDetails, setPhysicianDetails] = useState(null);

    useEffect(() => {
        fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/approve-status/${token}`)
            .then(response => response.json())
            .then(data => {
                if (data.success) {
                    if (data.physician.isApproved) {     
                        const currentTime = Date.now();
                        if (data.physician.approvalTokenExpires && data.physician.approvalTokenExpires < currentTime) {
                            setResponseMessage('Link has expired');
                        } else {
                            setResponseMessage('Response has been submitted successfully!');
                        }
                    }
                } else {
                    toast.error('Error fetching physician details');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                toast.error('Error fetching physician details');
            });
    }, [token]);
    


    const handleApprovalDecision = (decision) => {
        if (decision === "reject") {
            setIsRejectLoad(true);
        } else if (decision === "accept") {
            setIsAcceptLoad(true);
        }

        fetch(`https://${process.env.REACT_APP_API_URL}/api/auth/approve/${token}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ decision }),
        })
        .then((response) => response.json())
        .then((data) => {
            if (data.success) {
                toast.success(`Registration ${decision}ed successfully.`);
                setResponseMessage('Response has been submitted successfully!');
            } else {
                toast.error(data.message || 'Something went wrong.');
            }
        })
        .catch(() => {
            toast.error('Error handling approval. Please try again later.');
        })
        .finally(() => {
            setIsAcceptLoad(false);
            setIsRejectLoad(false);
        });
    };

    return (
        <Card sx={{ padding: 4, maxWidth: 600, margin: 'auto', marginTop: '50px' }}>
            {responseMessage ? (
                <MDBox mb={3}>
                    <MDTypography variant="h4" textAlign="center">
                        {responseMessage}
                    </MDTypography>
                </MDBox>
            ) : (
                <>
                    <MDBox mb={3}>
                        <MDTypography variant="h4" textAlign="center">
                            Approve Physician Registration
                        </MDTypography>
                    </MDBox>
                    <MDBox mb={3}>
                        <MDTypography variant="body1" textAlign="center">
                            Do you want to approve or reject this physician's registration?
                        </MDTypography>
                    </MDBox>
                    <MDBox display="flex" justifyContent="center" gap={2}>
                        <MDButton
                            color="success"
                            disabled={isAcceptLoad}
                            onClick={() => handleApprovalDecision('accept')}
                        >
                            {isAcceptLoad ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                'Approve'
                            )}
                        </MDButton>
                        <MDButton
                            color="error"
                            disabled={isRejectLoad}
                            onClick={() => handleApprovalDecision('reject')}
                        >
                            {isRejectLoad ? (
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                'Reject'
                            )}
                        </MDButton>
                    </MDBox>
                </>
            )}
        </Card>
    );
}

export default AdminApproval;