import React, { useEffect, useState, useContext } from "react";
import { AuthContext } from "context";
import {
  Box,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Button,
  Typography,
  Grid,
  Modal,
  IconButton,
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MDSlider from "components/MDSlider/MDSlider";

const symptomsOptions = {
  nasalObstructionSeverity: ["Lieve", "Moderata", "Severa"],
  nasalObstructionLaterality: ["Unilaterale", "Bilaterale"],
  rhinorrheaPresenceOptions: ["Yes", "No"],
  rhinorrheaSecretion: ["Anteriore", "Posteriore", "AnteroPosteriore"],
  rhinorrheaCharacteristics: ["Acquosa", "Mucosa", "Purulenta"],
  reductionInSmell: ["Hyposmia", "Anosmia"],
  reductionInTaste: ["Hypogeusia", "Ageusia"],
  painPresence: ["Yes", "No"],
  painFrequency: ["Sporadic", "Intermittent", "Continuous"],
  otherSymptoms: ["Disturbi del Sonno", "Prurito Nasale", "Starnuti"],
};

const MDReportModal = ({
  open,
  handleClose,
  AddPatientReports,
  EditPatientReports,
  reportData,
  isDisabled = false,
}) => {
  const [formData, setFormData] = useState({
    nasalObstructionSeverity: "",
    nasalObstructionLaterality: "",
    rhinorrheaPresence: "",
    rhinorrheaSecretion: "",
    rhinorrheaCharacteristics: "",
    hyposmiaPresence: "",
    hyposmiaLevel: 0,
    reductionInSmellDuration: "",
    reductionInTastePresence: "",
    reductionInTasteLevel: 0,
    reductionInTasteDuration: "",
    painPresence: "",
    painIntensity: 0,
    painFrequency: "",
    otherSymptoms: [],
    snot22Score: "",
    igeValue: "",
    eosinophilCount: "",
    nasalCytologyEosinophilCount: "",
    tissueEosinophilCount: "",
  });

  const {
    userData: { role },
  } = useContext(AuthContext);
  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    if (reportData) {
      setFormData(reportData);
    }
  }, [reportData]);

  const validateField = (name, value) => {
    let error = "";

    switch (name) {
      case "nasalObstructionSeverity":
      case "nasalObstructionLaterality":
      case "rhinorrheaPresence":
      case "rhinorrheaSecretion":
      case "rhinorrheaCharacteristics":
      case "hyposmiaPresence":
      case "reductionInSmellDuration":
      case "reductionInTastePresence":
      case "reductionInTasteDuration":
      case "painPresence":
      case "painFrequency":
        if (!value) {
          error = "This field is required.";
        }
        break;

      case "hyposmiaLevel":
      case "reductionInTasteLevel":
      case "painIntensity":
        if (value === "") {
          error = "This field is required.";
        } else if (isNaN(value) || value < 0 || value > 10) {
          error = "Must be a number between 0 and 10.";
        }
        break;

      case "snot22Score":
        if (!value) {
          error = "This field is required.";
        } else if (isNaN(value) || value < 0 || value > 110) {
          error = "Must be a number between 0 and 110.";
        }
        break;

      case "igeValue":
      case "eosinophilCount":
      case "nasalCytologyEosinophilCount":
      case "tissueEosinophilCount":
        if (!value) {
          error = "This field is required.";
        } else if (isNaN(value) || value < 0) {
          error = "Must be a non-negative number.";
        }
        break;

      default:
        break;
    }

    return error;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === "checkbox") {
      setFormData((prev) => ({
        ...prev,
        [name]: checked
          ? [...prev[name], value]
          : prev[name].filter((v) => v !== value),
      }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        [name]: validateField(name, value),
      }));
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    // Validate field on blur
    setFormErrors((prevErrors) => ({
      ...prevErrors,
      [name]: validateField(name, value),
    }));
  };

  const validateForm = () => {
    const errors = {};
    Object.keys(formData).forEach((key) => {
      const error = validateField(key, formData[key]);
      if (error) errors[key] = error;
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      AddPatientReports(formData);
      handleClose();
      setFormData({
        nasalObstructionSeverity: "",
        nasalObstructionLaterality: "",
        rhinorrheaPresence: "",
        rhinorrheaSecretion: "",
        rhinorrheaCharacteristics: "",
        hyposmiaPresence: "",
        hyposmiaLevel: 0,
        reductionInSmellDuration: "",
        reductionInTastePresence: "",
        reductionInTasteLevel: 0,
        reductionInTasteDuration: "",
        painPresence: "",
        painIntensity: 0,
        painFrequency: "",
        otherSymptoms: [],
        snot22Score: "",
        igeValue: "",
        eosinophilCount: "",
        nasalCytologyEosinophilCount: "",
        tissueEosinophilCount: "",
      });
    }
  };

  const handleUpdate = () => {
    if (validateForm()) {
      EditPatientReports(formData);
      handleClose();
      setFormData({
        nasalObstructionSeverity: "",
        nasalObstructionLaterality: "",
        rhinorrheaPresence: "",
        rhinorrheaSecretion: "",
        rhinorrheaCharacteristics: "",
        hyposmiaPresence: "",
        hyposmiaLevel: 0,
        reductionInSmellDuration: "",
        reductionInTastePresence: "",
        reductionInTasteLevel: 0,
        reductionInTasteDuration: "",
        painPresence: "",
        painIntensity: 0,
        painFrequency: "",
        otherSymptoms: [],
        snot22Score: "",
        igeValue: "",
        eosinophilCount: "",
        nasalCytologyEosinophilCount: "",
        tissueEosinophilCount: "",
      });
    }
  };

  const handlecloseAll = () => {
    handleClose();
    setFormErrors({});
    setFormData({
      nasalObstructionSeverity: "",
      nasalObstructionLaterality: "",
      rhinorrheaPresence: "",
      rhinorrheaSecretion: "",
      rhinorrheaCharacteristics: "",
      hyposmiaPresence: "",
      hyposmiaLevel: 0,
      reductionInSmellDuration: "",
      reductionInTastePresence: "",
      reductionInTasteLevel: 0,
      reductionInTasteDuration: "",
      painPresence: "",
      painIntensity: 0,
      painFrequency: "",
      otherSymptoms: [],
      snot22Score: "",
      igeValue: "",
      eosinophilCount: "",
      nasalCytologyEosinophilCount: "",
      tissueEosinophilCount: "",
    });
  };

  const handleSliderChange = (name) => (event, newValue) => {
    setFormData((prev) => ({ ...prev, [name]: newValue }));
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handlecloseAll()}
        aria-labelledby="add-modal-title"
        aria-describedby="add-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 5,
            padding: 6,
            width: 900,
            maxHeight: 800,
            overflowY: "auto",
            textAlign: "center",
            position: "relative",
          }}
        >
          <IconButton
            onClick={() => handlecloseAll()}
            sx={{ position: "absolute", top: 10, right: 10 }}
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h4" mb={2}>
            Patient Medical Report (Symptoms)
          </Typography>
          <Box component="form" onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {/* Symptoms Section */}
              <Grid item xs={12}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ textAlign: "start" }}
                >
                  Nasal Obstruction
                </Typography>
                {/* Nasal Obstruction */}
                <Box>
                  <TextField
                    select
                    label="Nasal Obstruction Severity"
                    name="nasalObstructionSeverity"
                    value={formData.nasalObstructionSeverity}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mb: 4 }}
                    InputProps={{
                      sx: {
                        lineHeight: "3em",
                      },
                    }}
                    error={!!formErrors.nasalObstructionSeverity}
                    helperText={formErrors.nasalObstructionSeverity}
                    disabled={isDisabled}
                  >
                    {symptomsOptions.nasalObstructionSeverity.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <TextField
                    select
                    label="Nasal Obstruction Laterality"
                    name="nasalObstructionLaterality"
                    value={formData.nasalObstructionLaterality}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mb: 4 }}
                    InputProps={{
                      sx: {
                        lineHeight: "3em", // Adjust line height as needed
                      },
                    }}
                    error={!!formErrors.nasalObstructionLaterality}
                    helperText={formErrors.nasalObstructionLaterality}
                    disabled={isDisabled}
                  >
                    {symptomsOptions.nasalObstructionLaterality.map(
                      (option) => (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      )
                    )}
                  </TextField>

                  {/* Rhinorrhea Section */}
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ textAlign: "start" }}
                  >
                    Rinorrea (Rhinorrhea)
                  </Typography>

                  {/* Rhinorrhea Presence */}
                  <TextField
                    select
                    label="Rhinorrhea Presence"
                    name="rhinorrheaPresence"
                    value={formData.rhinorrheaPresence}
                    onChange={handleChange}
                    fullWidth
                    sx={{ mb: 4 }}
                    error={!!formErrors.rhinorrheaPresence}
                    helperText={formErrors.rhinorrheaPresence}
                    InputProps={{
                      sx: {
                        lineHeight: "3em",
                      },
                    }}
                    disabled={isDisabled}
                  >
                    {symptomsOptions.rhinorrheaPresenceOptions.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  {/* Conditional fields (only show when rhinorrheaPresence is "Yes") */}
                  {formData.rhinorrheaPresence === "Yes" && (
                    <>
                      <TextField
                        select
                        label="Rhinorrhea Secretion"
                        name="rhinorrheaSecretion"
                        value={formData.rhinorrheaSecretion}
                        onChange={handleChange}
                        fullWidth
                        sx={{ mb: 4 }}
                        error={!!formErrors.rhinorrheaSecretion}
                        helperText={formErrors.rhinorrheaSecretion}
                        InputProps={{
                          sx: {
                            lineHeight: "3em",
                          },
                        }}
                        disabled={isDisabled}
                      >
                        {symptomsOptions.rhinorrheaSecretion.map((option) => (
                          <MenuItem key={option} value={option}>
                            {option}
                          </MenuItem>
                        ))}
                      </TextField>

                      <TextField
                        select
                        label="Rhinorrhea Characteristics"
                        name="rhinorrheaCharacteristics"
                        value={formData.rhinorrheaCharacteristics}
                        onChange={handleChange}
                        fullWidth
                        sx={{ mb: 4 }}
                        error={!!formErrors.rhinorrheaCharacteristics}
                        helperText={formErrors.rhinorrheaCharacteristics}
                        InputProps={{
                          sx: {
                            lineHeight: "3em",
                          },
                        }}
                        disabled={isDisabled}
                      >
                        {symptomsOptions.rhinorrheaCharacteristics.map(
                          (option) => (
                            <MenuItem key={option} value={option}>
                              {option}
                            </MenuItem>
                          )
                        )}
                      </TextField>
                    </>
                  )}

                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ textAlign: "start" }}
                  >
                    Iposmia/Anosmia (Hyposmia/Anosmia)
                  </Typography>
                  {/* Hyposmia Section */}
                  <Grid item xs={12}>
                    {/* Hyposmia Yes/No */}
                    <FormControl fullWidth sx={{ mb: 4 }}>
                      <InputLabel>Hyposmia Presence</InputLabel>
                      <Select
                        name="hyposmiaPresence"
                        label="Hyposmia Presence"
                        value={formData.hyposmiaPresence}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        error={!!formErrors.hyposmiaPresence}
                        sx={{
                          lineHeight: "3em",
                        }}
                        disabled={isDisabled}
                      >
                        <MenuItem value="Yes">Yes</MenuItem>
                        <MenuItem value="No">No</MenuItem>
                      </Select>
                      <FormHelperText error={!!formErrors.hyposmiaPresence}>
                        {formErrors.hyposmiaPresence}
                      </FormHelperText>
                    </FormControl>

                    {/* Hyposmia Slider */}
                    {formData.hyposmiaPresence === "Yes" && (
                      <Box sx={{ mb: 4, display: "flex", gap: "100px" }}>
                        <Typography variant="body2" gutterBottom>
                          Hyposmia Level
                        </Typography>
                        <MDSlider
                          value={formData.hyposmiaLevel}
                          onChange={handleSliderChange("hyposmiaLevel")}
                          min={0}
                          max={10}
                          step={1}
                          marks={[
                            { value: 0, label: "Normosmia" },
                            { value: 10, label: "Anosmia" },
                          ]}
                          label0Text="Normosmia"
                          label10Text="Ageusic"
                          disabled={isDisabled}
                        />
                      </Box>
                    )}
                  </Grid>

                  {/* Duration of Hyposmia */}
                  <Grid item xs={12}>
                    <TextField
                      select
                      label="Duration of Hyposmia/Anosmia"
                      name="reductionInSmellDuration"
                      value={formData.reductionInSmellDuration}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      fullWidth
                      sx={{ mb: 4 }}
                      InputProps={{
                        sx: {
                          lineHeight: "3em",
                        },
                      }}
                      error={!!formErrors.reductionInSmellDuration}
                      helperText={formErrors.reductionInSmellDuration}
                      disabled={isDisabled}
                    >
                      <MenuItem value="Short">
                        Short (less than 1 month)
                      </MenuItem>
                      <MenuItem value="Medium">Medium (1-6 months)</MenuItem>
                      <MenuItem value="Long">Long (over 6 months)</MenuItem>
                    </TextField>
                  </Grid>

                  <FormControl fullWidth sx={{ mb: 4 }}>
                    <InputLabel>Reduction in Taste Presence</InputLabel>
                    <Select
                      name="reductionInTastePresence"
                      value={formData.reductionInTastePresence}
                      label="Reduction in Taste Presence"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      error={!!formErrors.reductionInTastePresence}
                      sx={{
                        lineHeight: "3em",
                      }}
                      disabled={isDisabled}
                    >
                      <MenuItem value="Yes">Yes</MenuItem>
                      <MenuItem value="No">No</MenuItem>
                    </Select>
                    <FormHelperText
                      error={!!formErrors.reductionInTastePresence}
                    >
                      {formErrors.reductionInTastePresence}
                    </FormHelperText>
                  </FormControl>

                  {formData.reductionInTastePresence === "Yes" && (
                    <Box sx={{ mb: 4, display: "flex", gap: "100px" }}>
                      <Typography
                        variant="body2"
                        gutterBottom
                        sx={{ textAlign: "start" }}
                      >
                        Level of Taste Reduction
                      </Typography>
                      <MDSlider
                        value={formData.reductionInTasteLevel}
                        onChange={handleSliderChange("reductionInTasteLevel")}
                        aria-label="Taste Reduction Level"
                        min={0}
                        max={10}
                        step={1}
                        marks={[
                          { value: 0, label: "Normogeusia" },
                          { value: 10, label: "Ageusia" },
                        ]}
                        label0Text="Normogeusia"
                        label10Text="Ageusic"
                        disabled={isDisabled}
                      />
                    </Box>
                  )}

                  <TextField
                    select
                    label="Duration of Reduction in Taste"
                    name="reductionInTasteDuration"
                    value={formData.reductionInTasteDuration}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mb: 4 }}
                    InputProps={{
                      sx: {
                        lineHeight: "3em",
                      },
                    }}
                    error={!!formErrors.reductionInTasteDuration}
                    helperText={formErrors.reductionInTasteDuration}
                    disabled={isDisabled}
                  >
                    <MenuItem value="Short">Short (less than 1 month)</MenuItem>
                    <MenuItem value="Medium">Medium (1-6 months)</MenuItem>
                    <MenuItem value="Long">Long (over 6 months)</MenuItem>
                  </TextField>

                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ textAlign: "start" }}
                  >
                    Dolore/Pressione Facciale (Pain/Facial Pressure)
                  </Typography>
                  {/* Pain/Facial Pressure */}
                  <TextField
                    select
                    label="Pain Presence"
                    name="painPresence"
                    value={formData.painPresence}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mb: 4 }}
                    InputProps={{
                      sx: {
                        lineHeight: "3em",
                      },
                    }}
                    error={!!formErrors.painPresence}
                    helperText={formErrors.painPresence}
                    disabled={isDisabled}
                  >
                    {symptomsOptions.painPresence.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>

                  <Box sx={{ mb: 4, display: "flex", gap: "100px" }}>
                    <Typography
                      variant="body2"
                      gutterBottom
                      sx={{ textAlign: "start" }}
                    >
                      Pain Intensity
                    </Typography>
                    <MDSlider
                      value={formData.painIntensity}
                      onChange={handleSliderChange("painIntensity")}
                      aria-label="Pain Intensity"
                      min={0}
                      max={10}
                      step={1}
                      marks={[
                        { value: 0, label: "No pain" },
                        { value: 10, label: "Severe pain" },
                      ]}
                      label0Text="No pain"
                      label10Text="Severe pain"
                      disabled={isDisabled}
                    />
                  </Box>

                  <TextField
                    select
                    label="Pain Frequency"
                    name="painFrequency"
                    value={formData.painFrequency}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    fullWidth
                    sx={{ mb: 4 }}
                    InputProps={{
                      sx: {
                        lineHeight: "3em", // Adjust line height as needed
                      },
                    }}
                    error={!!formErrors.painFrequency}
                    helperText={formErrors.painFrequency}
                    disabled={isDisabled}
                  >
                    {symptomsOptions.painFrequency.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </TextField>
                </Box>
                {/* Other Symptoms */}
                <Box>
                  <Typography
                    variant="subtitle1"
                    gutterBottom
                    sx={{ textAlign: "start" }}
                  >
                    Altri Sintomi (Other Symptoms)
                  </Typography>
                  <Box style={{ display: "flex", gap: "20px" }}>
                    {symptomsOptions.otherSymptoms.map((option) => (
                      <FormControlLabel
                        key={option}
                        control={
                          <Checkbox
                            name="otherSymptoms"
                            value={option}
                            checked={formData?.otherSymptoms?.includes(option)}
                            onChange={handleChange}
                            disabled={isDisabled}
                          />
                        }
                        label={option}
                      />
                    ))}
                  </Box>
                </Box>
                <br />

                {/* Medical Parameters */}

                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ textAlign: "start" }}
                >
                  Medical Parameters
                </Typography>
                <TextField
                  label="SNOT22 Score "
                  name="snot22Score"
                  type="number"
                  value={formData.snot22Score}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ mb: 2 }}
                  error={!!formErrors.snot22Score}
                  helperText={formErrors.snot22Score}
                  disabled={isDisabled}
                />

                <TextField
                  label="IgE Value (IU/mL)"
                  name="igeValue"
                  type="number"
                  value={formData.igeValue}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ mb: 2 }}
                  error={!!formErrors.igeValue}
                  helperText={formErrors.igeValue}
                  disabled={isDisabled}
                />

                <TextField
                  label="Eosinophil Count (cells/µL)"
                  name="eosinophilCount"
                  type="number"
                  value={formData.eosinophilCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ mb: 2 }}
                  error={!!formErrors.eosinophilCount}
                  helperText={formErrors.eosinophilCount}
                  disabled={isDisabled}
                />

                <TextField
                  label="Nasal Cytology Eosinophil Count (%)"
                  name="nasalCytologyEosinophilCount"
                  type="number"
                  value={formData.nasalCytologyEosinophilCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ mb: 2 }}
                  error={!!formErrors.nasalCytologyEosinophilCount}
                  helperText={formErrors.nasalCytologyEosinophilCount}
                  disabled={isDisabled}
                />

                <TextField
                  label="Tissue Eosinophil Count (cells/hpf)"
                  name="tissueEosinophilCount"
                  type="number"
                  value={formData.tissueEosinophilCount}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  fullWidth
                  sx={{ mb: 2 }}
                  error={!!formErrors.tissueEosinophilCount}
                  helperText={formErrors.tissueEosinophilCount}
                  disabled={isDisabled}
                />
              </Grid>

              {role === "physician" &&
                new URL(window.location.href).pathname !==
                  "/hospital-wide-data" && (
                  <Grid item xs={12}>
                    {reportData !== "" ? (
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        onClick={() => handleUpdate()}
                      >
                        Update
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                      >
                        Save
                      </Button>
                    )}
                    {"   "}
                    {reportData !== "" && (
                      <Button
                        type="button"
                        variant="contained"
                        color="primary"
                        style={{ color: "white" }}
                        onClick={handlecloseAll}
                      >
                        cancel
                      </Button>
                    )}
                  </Grid>
                )}
            </Grid>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default MDReportModal;
