import * as React from 'react';
import Box from '@mui/material/Box';
import Slider from '@mui/material/Slider';
import { createTheme, ThemeProvider } from '@mui/material/styles';

function valuetext(value) {
    return `${value}`;
}


const theme = createTheme({
    palette: {
        primary: {
            main: '#1976D2',
            light: 'rgba(25, 118, 210, 0.5)',
        },
    },
});

export default function MDSlider({
    min = 0,
    max = 10,
    step = 1,
    value,
    marks = [],
    valueLabelDisplay = 'auto',
    ariaLabel = 'Slider',
    onChange,
    label0 = '',
    label10 = '',
    label0Text = '',
    label10Text = '',
    disabled=false,
}) {

    const [sliderValue, setSliderValue] = React.useState(value || min);

    React.useEffect(() => {
        if (value !== undefined) {
            setSliderValue(value);
        }
    }, [value]);

    
    const handleChange = (event, newValue) => {
        setSliderValue(newValue);
        if (onChange) {
            onChange(event, newValue);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{ width: 300, textAlign: 'center' }}>
                <Slider
                    aria-label={ariaLabel}
                    value={sliderValue}
                    getAriaValueText={valuetext}
                    valueLabelDisplay={valueLabelDisplay}
                    step={step}
                    marks={[
                        { value: 0, label: label0 },
                        { value: 10, label: label10 },
                    ]}
                    min={min}
                    max={max}
                    onChange={handleChange}
                    sx={{ color: theme.palette.primary.main }}
                    disabled={disabled}
                />
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <span style={{ fontSize: '0.75rem' }}>{label0Text}</span>
                    <span style={{ fontSize: '0.75rem' }}>{label10Text}</span>
                </Box>
            </Box>
        </ThemeProvider>
    );
}