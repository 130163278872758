/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import TimelineItem from "examples/Timeline/TimelineItem";

function OrdersOverview() {
  return (
    <Card sx={{ height: "100%" }}>
      <MDBox pt={3} px={3}>
        <MDTypography variant="h6" fontWeight="medium">
          Patient Appointments
        </MDTypography>
        <MDBox mt={0} mb={2}>
          <MDTypography variant="button" color="text" fontWeight="regular">
            <MDTypography display="inline" variant="body2" verticalAlign="middle">
              <Icon sx={{ color: ({ palette: { success } }) => success.main }}>arrow_upward</Icon>
            </MDTypography>
            &nbsp;
            <MDTypography variant="button" color="text" fontWeight="medium">
              12%
            </MDTypography>{" "}
            this month
          </MDTypography>
        </MDBox>
      </MDBox>
      <MDBox p={2}>
        <TimelineItem
          color="success"
          icon="event"
          title="Appointment with Dr. Smith"
          dateTime="22 AUG 10:00 AM"
        />
        <TimelineItem
          color="error"
          icon="cancel"
          title="Cancelled appointment with Dr. Johnson "
          dateTime="21 AUG 3:00 PM"
        />
        <TimelineItem
          color="info"
          icon="schedule"
          title="Upcoming appointment with Dr. Nguyen"
          dateTime="24 AUG 9:30 AM"
        />
        <TimelineItem
          color="warning"
          icon="medical_services"
          title="Scheduled procedure for patient John Doe"
          dateTime="25 AUG 11:00 AM"
        />
        <TimelineItem
          color="primary"
          icon="healing"
          title="Follow-up visit with Dr. Lee"
          dateTime="28 AUG 2:00 PM"
          lastItem
        />
      </MDBox>
    </Card>
  );
}

export default OrdersOverview;
