import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import { CircularProgress } from "@mui/material";
import MDHistoryForm from "components/MDHistoryForm/MDHistoryForm";
import MDReportForm from "components/MDReportForm/MDReportForm";
import { jwtDecode } from "jwt-decode";
import MDButton from "components/MDButton";
import toast from "react-hot-toast";
import AddIcon from "@mui/icons-material/Add";
import "./index.css";

const PatientDetails = () => {
  const { id } = useParams();
  const [medicalHistory, setMedicalHistory] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMdReport, setIsLoadingMdReport] = useState(false);
  const [isSaveData, setIsSaveData] = useState(false);
  const [medicalReportData, setMedicalReportData] = useState("");
  const [totalMedicalHistory, setTotalMedicalHistory] = useState([]);
  const [totalMedicalReportData, setTotalMedicalReportData] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [activeId, setActiveId] = useState(null);
  const [renderUpdate, setRenderUpdate] = useState(false);
  const submitRef=useRef(false)

  const handleGetMedicalReportAndHistory = () => {
    setIsLoading(true);

    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/patient-history-datewise/${id}`)
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setTotalMedicalHistory(data?.patientHistory);
          setActiveId(
            data?.patientHistory[data.patientHistory.length - 1]?._id
          );
          setMedicalHistory(
            data?.patientHistory[data.patientHistory.length - 1]?.latestHistory
          );
        }
      })
      .catch((error) => {
        console.error("Error fetching medical history:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });

    setIsLoading(true);

    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/medical-report-datewise/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setIsLoading(false);
          console.log(data?.medicalReports, "data.medicalReports coming in");
          setTotalMedicalReportData(data?.medicalReports);
          setMedicalReportData(
            data?.medicalReports[data.medicalReports.length - 1]?.latestReport
          );
        } else {
          setMedicalReportData("");
          setIsLoading(false);
        }
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    handleGetMedicalReportAndHistory();
  }, [id]);

  console.log(
    totalMedicalHistory,
    "totalMedicalHistory",
    totalMedicalReportData
  );
  const handleAddPatientReports = (data) => {
    // setIsLoadingMdReport(true);
    const token = localStorage.getItem("token");
    let userId = null;

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        userId = decodedToken.user.id;
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }

    const dataBody = {
      ...data,
      physicianId: userId,
      patientId: id,
    };
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/create-patient-report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          toast.success("Patient Report Saved Succesfully");
          handleGetMedicalReportAndHistory();
          setIsLoadingMdReport(false);
        } else {
          console.log("error");
          // setIsLoadingMdReport(false);
        }
      })
      .catch((err) => {
        // setIsLoadingMdReport(false);
        console.log(err, "getting err while saving patient");
        toast.error("Patient not Saved Successfully");
        // setIsLoading(false);
      });
  };

  const handleButtonClick = (history) => {
    setActiveId(history._id);
    setMedicalHistory(history.latestHistory);
    console.log(totalMedicalReportData, "totalMedicalReportData");
    let showMdReport = totalMedicalReportData.filter(
      (report) => report._id === history._id
    )[0];
    console.log(showMdReport, "showMdReport");
    setMedicalReportData(showMdReport.latestReport);
  };

  const handleUpdateNew = () => {
    setRenderUpdate(true);
    setIsDisabled(false);
  };

  const handleUpdatePatientRecord = () => {
    // if (!isSaveData) {
      // setIsSaveData(true);
      setRenderUpdate(false);
      submitRef.current= true;
      setIsDisabled(false);

    // }
  };

  console.log(renderUpdate, "renderUpdate-renderUpdate");

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {isLoading ? (
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          height="80vh"
        >
          <CircularProgress />
        </MDBox>
      ) : (
        <>
          <MDHistoryForm
            patientHistoryId={id}
            patientHistory={medicalHistory}
            isSaveData={isSaveData}
            onSaveComplete={() => setIsSaveData(false)}
            isDisabled={isDisabled}
            submitRef={submitRef}
          />
          {!isLoadingMdReport ? (
            <MDReportForm
              AddPatientReports={handleAddPatientReports}
              reportData={medicalReportData}
              isSaveData={isSaveData}
              isDisabled={isDisabled}
              submitRef={submitRef}
            />
          ) : (
            <MDBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="80vh"
            >
              <CircularProgress />
            </MDBox>
          )}

          {/* {medicalHistory && medicalHistory._id || medicalReportData && medicalReportData._id ? <MDButton color='dark' onClick={()=>setIsSaveData(true)}>Update</MDButton> : <MDButton onClick={()=>setIsSaveData(true)}> Save </MDButton>} */}
          {renderUpdate && (
            <div className="patientListAction">
              <MDButton
                color="dark"
                onClick={() => handleUpdatePatientRecord()}
              >
                {totalMedicalHistory.length === 0 ? "Save" : "Update"}
              </MDButton>
              <MDButton onClick={() => {setRenderUpdate(false);setIsDisabled(true);}}>Cancel</MDButton>
            </div>
          )}
          <div className="patientListFooter">
            <ul>
              {totalMedicalHistory.map((history, index) => (
                <li key={index}>
                  <MDButton
                    className={activeId === history._id ? "isActive" : ""}
                    onClick={() => handleButtonClick(history)}
                  >
                    {history._id}{" "}
                  </MDButton>
                </li>
              ))}
              <li>
                <MDButton className="addBtn" onClick={() => handleUpdateNew()}>
                  <AddIcon />
                </MDButton>
              </li>
            </ul>
          </div>
        </>
      )}
    </DashboardLayout>
  );
};

export default PatientDetails;
