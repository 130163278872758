import React from "react";
import "./ConsentFormPrint.css";

const ConsentFormPrint = ({ contentREf }) => {
  return (
    <div className="container" ref={contentREf}>
      <h1>NPmonitor.it</h1>
      <h2>
        Consenso Informato per il Trattamento dei Dati Personali e Sanitari
      </h2>
      <div className="wrapper">
        <p>
          Ai sensi del Regolamento (UE) 2016/679 (GDPR) e delle normative
          vigenti in materia di protezione dei dati personali, si informa il
          paziente sui seguenti aspetti riguardanti il trattamento dei propri
          dati personali e sanitari.
        </p>

        <h3>Finalità del trattamento dei dati</h3>
        <p>
          I dati personali e sanitari raccolti verranno trattati per le seguenti
          finalità:
        </p>
        <ul>
          <li>Gestione clinica e monitoraggio del paziente</li>
          <li>Ricerca scientifica</li>
          <li>Analisi epidemiologiche</li>
        </ul>

        <h3>Modalità di raccolta del consenso</h3>
        <p>
          Il consenso al trattamento dei dati potrà essere raccolto in una delle
          seguenti modalità, a discrezione del medico curante:
        </p>
        <ul>
          <li>
            Via email, con un invito a opporsi entro 48 ore (silenzio-assenso)
          </li>
          <li>In forma cartacea con firma</li>
          <li>Tramite SMS</li>
        </ul>

        <h3>Durata del trattamento e conservazione dei dati</h3>
        <p>
          I dati personali e sanitari saranno conservati nel sistema per tutta
          la durata del trattamento del paziente e per un periodo standard di{" "}
          <strong>10 anni</strong> dopo la conclusione del trattamento, come
          previsto dalla normativa in materia di conservazione dei dati
          sanitari. Trascorso tale periodo, i dati verranno eliminati in modo
          sicuro.
        </p>

        <h3>Diritti del paziente</h3>
        <p>Il paziente ha il diritto di:</p>
        <ul>
          <li>Accedere ai propri dati personali in qualsiasi momento</li>
          <li>Richiedere la rettifica di eventuali dati inesatti</li>
          <li>
            Richiedere la cancellazione dei propri dati quando non più necessari
            per le finalità descritte
          </li>
          <li>
            Limitare il trattamento dei propri dati in determinate circostanze
          </li>
          <li>
            Richiedere la portabilità dei propri dati a un altro titolare del
            trattamento
          </li>
        </ul>

        <h3>Revoca del consenso</h3>
        <p>
          Il paziente può revocare il consenso in qualsiasi momento mediante una
          richiesta scritta inviata al proprio medico curante. La richiesta deve
          essere inoltrata via email all’indirizzo fornito dal medico curante o
          a
          <a href="mailto:domenicodimaria@gmail.com">
            {" "}
            domenicodimaria@gmail.com
          </a>
          . La revoca del consenso non pregiudica la liceità del trattamento
          effettuato prima della revoca.
        </p>

        <h3>Protezione dei dati personali</h3>
        <p>
          I dati personali del paziente saranno protetti da misure di sicurezza
          avanzate, comprese la crittografia dei dati a riposo e in transito,
          per garantirne la riservatezza e l'integrità.
        </p>

        <h3>Visualizzazione dei dati da parte del titolare del sistema</h3>
        <p>
          Il titolare del sistema, il dott. Domenico Di Maria, potrà accedere ai
          dati dei pazienti solo in forma anonima, limitandosi a visualizzare
          informazioni aggregate per scopi di ricerca e analisi epidemiologica.
        </p>

        <h3>Condivisione dei dati anonimi</h3>
        <p>
          I dati del paziente, in forma anonima, potranno essere condivisi con
          il titolare del sistema per scopi di ricerca scientifica ed
          epidemiologici.
        </p>

        <h3>Misure di sicurezza adottate</h3>
        <p>
          Il sistema implementa misure di sicurezza avanzate per garantire la
          protezione dei dati personali e sanitari del paziente, inclusa la
          crittografia dei dati e l'accesso controllato ai dati da parte del
          medico curante.
        </p>

        <h3>Domande o richieste</h3>
        <p>
          Per qualsiasi domanda o richiesta riguardante i propri dati personali,
          il paziente potrà rivolgersi al proprio medico curante o inviare una
          richiesta all’indirizzo email{" "}
          <a href="mailto:domenicodimaria@gmail.com">
            domenicodimaria@gmail.com
          </a>
          .
        </p>

        <div className="signature-section">
          <p>
            Luogo <span>__________</span>
          </p>
          <p>
            Data <span>____________</span>
          </p>
          <p>
            Firma Leggibile <span>____________</span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConsentFormPrint;
