import React, { useContext, useEffect, useRef, useState } from "react";
import {
  Modal,
  Box,
  IconButton,
  Typography,
  Card,
  Grid,
  FormControlLabel,
  TextField,
  Checkbox,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import "./MyForm.css";
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import toast from "react-hot-toast";
import CircularProgress from "@mui/material/CircularProgress";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import { AuthContext } from "context";
import SNOT22Dialog from "components/SNOT-22/SNOT22Dialog";

const validationSchema = Yup.object().shape({
  // Add your validation schema here
});

const MDHistoryForm = ({
  patientHistoryId,
  patientHistory,
  isSaveData,
  onSaveComplete,
  isDisabled=false,
  submitRef,
}) => {
  console.log(patientHistory, "patientHistory comin ibn proppp");
  const [formHistoryData, setFormHistoryData] = useState({
    SymptomDuration: {
      Duration: "",
      ContinuousOrIntermittent: "",
    },
    PreviousTreatments: {
      FreeTextField: "",
    },
    Medications: {
      OralCorticosteroids: {
        Used: false,
        NumberOfCyclesPerYear: "",
        Dosage: "",
      },
      TopicalCorticosteroids: {
        Used: false,
        NumberOfMonthsPerYear: "",
        Dosage: "",
      },
      Antihistamines: {
        Used: false,
      },
      Decongestants: {
        Used: false,
      },
      CorticosteroidsRetard: {
        Used: false,
      },
      TopicalCorticosteroidsAntihistamines: {
        Used: false,
      },
      LeukotrieneAntagonists: {
        Used: false,
      },
    },
    HadSurgicalIntervention: false,
    SurgicalInterventions: [
      {
        ProcedureType: "",
        HistologicalReport: "",
      },
    ],
    BiologicTherapies: {
      Mepolizumab: {
        used: false,
        duration: "",
        currentTherapy: false,
        startYear: "",
        endYear: "",
        switch: "",
        switchReason: "",
      },
      Omalizumab: {
        used: false,
        duration: "",
        currentTherapy: false,
        startYear: "",
        endYear: "",
        switch: "",
        switchReason: "",
      },
      Dupilumab: {
        used: false,
        duration: "",
        currentTherapy: false,
        startYear: "",
        endYear: "",
        switch: "",
        switchReason: "",
      },
      Benralizumab: {
        used: false,
        duration: "",
        currentTherapy: false,
        startYear: "",
        endYear: "",
        switch: "",
        switchReason: "",
      },
      Other: { used: false, freeTextField: "" },
    },
    Comorbidities: {
      Asthma: false,
      Allergies: {
        Used: false,
        SpecificTypes: [],
      },
      CysticFibrosis: false,
      ChurgStraussSyndrome: false,
      HyperEosinophilia: false,
      ASASyndrome: false,
      ImmuneDeficiencies: false,
      Other: {
        Used: false,
        FreeTextField: "",
      },
    },
    MedicationUsage: {
      Aspirin: false,
      OtherNSAIDs: false,
      Other: {
        Used: false,
        FreeTextField: "",
      },
    },
    ObjectiveExamination: {
      AnteriorRhinoscopy: {
        PresenceOfPolyps: false,
        TurbinateHypertrophy: false,
        Secretions: false,
        Other: {
          Used: false,
          FreeTextField: "",
        },
      },
      NasalEndoscopy: {
        Dx: "",
        Sx: "",
      },
    },
    AdditionalInvestigations: {
      LundMcKayScore: "",
      Rhinomanometry: {
        AssessmentOfNasalAirflow: false,
        Results: "",
      },
      OssTest: "",
      SNOT: {
        needToBlowNose: 0,
        sneezing: 0,
        runnyNose: 0,
        cough: 0,
        postNasalDischarge: 0,
        thickNasalDischarge: 0,
        earFullness: 0,
        dizziness: 0,
        earPainPressure: 0,
        facialPainPressure: 0,
        difficultyFallingAsleep: 0,
        wakingUpAtNight: 0,
        lackOfGoodSleep: 0,
        wakingUpTired: 0,
        fatigueDuringDay: 0,
        reducedProductivity: 0,
        reducedConcentration: 0,
        frustratedIrritable: 0,
        sad: 0,
        embarrassed: 0,
        senseOfTasteSmell: 0,
        nasalBlockageCongestion: 0,
      },
    },
    AdditionalInformation: {
      PatientExpectations: {
        TherapyGoals: {
          ImprovementOfSymptoms: false,
          ReductionInNeedForMedications: false,
          ReductionInNeedForSurgicalInterventions: false,
          Other: {
            Used: false,
            FreeTextField: "",
          },
        },
      },
    },
  });
  const [isSubmitted, setIsSubmitted] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const {
    userData: { role, token },
  } = useContext(AuthContext);

  const [formErrors, setFormErrors] = useState({});
  const [biologicTherapies, setBiologicTherapies] = useState({
    Mepolizumab: {
      used: false,
      duration: "",
      currentTherapy: false,
      startYear: "",
      endYear: "",
      switch: "",
      switchReason: "",
    },
    Omalizumab: {
      used: false,
      currentTherapy: false,
      startYear: "",
      endYear: "",
      switch: "",
      switchReason: "",
    },
    Dupilumab: {
      used: false,
      currentTherapy: false,
      startYear: "",
      endYear: "",
      switch: "",
      switchReason: "",
    },
    Benralizumab: {
      used: false,
      currentTherapy: false,
      startYear: "",
      endYear: "",
      switch: "",
      switchReason: "",
    },
  });

  const [medications, setMedications] = useState({
    OralCorticosteroids: {
      used: false,
      numberOfCyclesPerYear: "",
      dosage: "",
    },
    TopicalCorticosteroids: {
      used: false,
      numberOfMonthsPerYear: "",
      dosage: "",
    },
    Antihistamines: { used: false },
    Decongestants: { used: false },
    CorticosteroidsRetard: {
      used: false,
    },
    TopicalCorticosteroidsAntihistamines: {
      used: false,
    },
    LeukotrieneAntagonists: {
      used: false,
    },
  });
  const [comorbidities, setComorbidities] = useState({
    Other: {
      used: false,
      freeTextField: "",
    },
  });

  const [anteriorRhinoscopy, setAnteriorRhinoscopy] = useState({
    Other: {
      used: false,
      freeTextField: "",
    },
  });

  const [medicationUsage, setMedicationUsage] = useState({
    Other: {
      used: false,
      freeTextField: "",
    },
  });

  const [therapyGoals, setTherapyGoals] = useState({
    Other: {
      used: false,
      freeTextField: "",
    },
  });

  const [olfactometry, setOlfactometry] = useState({
    Test1: { used: false, result: "" },
    Test2: { used: false, result: "" },
  });

  const handleCheckboxChangeTest = (test) => {
    setOlfactometry((prev) => ({
      ...prev,
      [test]: { ...prev[test], used: !prev[test].used },
    }));
  };

  // Handler for input change
  const handleInputChangeTest = (test, e) => {
    setOlfactometry((prev) => ({
      ...prev,
      [test]: { ...prev[test], result: e.target.value },
    }));
  };

  useEffect(() => {
    if (patientHistory === undefined) {
      setFormHistoryData({
        SymptomDuration: {
          Duration: "",
          ContinuousOrIntermittent: "",
        },
        PreviousTreatments: {
          FreeTextField: "",
        },
        Medications: {
          OralCorticosteroids: {
            Used: false,
            numberOfCyclesPerYear: "",
            activeIngredient: "",
          },
          TopicalCorticosteroids: {
            Used: false,
            NumberOfMonthsPerYear: "",
            activeIngredient: "",
          },
          Antihistamines: {
            Used: false,
          },
          Decongestants: {
            Used: false,
          },
          CorticosteroidsRetard: {
            Used: false,
          },
          TopicalCorticosteroidsAntihistamines: {
            Used: false,
          },
          LeukotrieneAntagonists: {
            Used: false,
          },
        },
        HadSurgicalIntervention: false,
        SurgicalInterventions: [
          {
            ProcedureType: "",
            HistologicalReport: "",
          },
        ],
        BiologicTherapies: {
          Mepolizumab: {
            used: false,
            duration: "",
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
          Omalizumab: {
            used: false,
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
          Dupilumab: {
            used: false,
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
          Benralizumab: {
            used: false,
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
        },
        Comorbidities: {
          Asthma: false,
          Allergies: {
            Used: false,
            SpecificTypes: [],
          },
          CysticFibrosis: false,
          ChurgStraussSyndrome: false,
          HyperEosinophilia: false,
          ASASyndrome: false,
          ImmuneDeficiencies: false,
          Other: {
            Used: false,
            FreeTextField: "",
          },
        },
        MedicationUsage: {
          Aspirin: false,
          OtherNSAIDs: false,
          Other: {
            Used: false,
            FreeTextField: "",
          },
        },
        ObjectiveExamination: {
          AnteriorRhinoscopy: {
            PresenceOfPolyps: false,
            TurbinateHypertrophy: false,
            Secretions: false,
            Other: {
              Used: false,
              FreeTextField: "",
            },
          },
          NasalEndoscopy: {
            Dx: "",
            Sx: "",
          },
        },
        AdditionalInvestigations: {
          LundMcKayScore: "",
          Rhinomanometry: {
            AssessmentOfNasalAirflow: false,
            Results: "",
          },
          OssTest: "",

          SNOT: {
            needToBlowNose: 0,
            sneezing: 0,
            runnyNose: 0,
            cough: 0,
            postNasalDischarge: 0,
            thickNasalDischarge: 0,
            earFullness: 0,
            dizziness: 0,
            earPainPressure: 0,
            facialPainPressure: 0,
            difficultyFallingAsleep: 0,
            wakingUpAtNight: 0,
            lackOfGoodSleep: 0,
            wakingUpTired: 0,
            fatigueDuringDay: 0,
            reducedProductivity: 0,
            reducedConcentration: 0,
            frustratedIrritable: 0,
            sad: 0,
            embarrassed: 0,
            senseOfTasteSmell: 0,
            nasalBlockageCongestion: 0,
          },
        },
        AdditionalInformation: {
          QualityOfLife: {
            ImpactOnDailyLife: "",
          },
          PatientExpectations: {
            TherapyGoals: {
              ImprovementOfSymptoms: false,
              ReductionInNeedForMedications: false,
              ReductionInNeedForSurgicalInterventions: false,
              Other: {
                Used: false,
                FreeTextField: "",
              },
            },
          },
        },
      });
    }
    if (patientHistory) {
      setFormHistoryData(patientHistory);
      setBiologicTherapies(patientHistory?.BiologicTherapies);
      setMedications(patientHistory?.Medications);
    }
  }, [patientHistory]);
  console.log(formHistoryData, "formHistoryData getingf valueee");

  const calculateTotalScore = (snotValues) => {
    try {
      console.log(snotValues, "snotValues inn calculate");
      const total = Object.entries(snotValues)
        .filter(([key]) => key !== "totalSNOTScore") // Exclude totalSNOTScore
        .reduce((acc, [key, value]) => acc + Number(value), 0);
      console.log(total, "total sccc");
      return total;
    } catch (error) {
      console.log(error);
    }
  };

  const handleSaveHistory = async (values) => {
    // Add a static flag to prevent multiple submissions
    if (handleSaveHistory.isSubmitting) return;
    handleSaveHistory.isSubmitting = true;
  
    try {
      const token = localStorage.getItem("token");
      let loggedInPhysicianId = null;
  
      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          loggedInPhysicianId = decodedToken.user.id;
        } catch (error) {
          console.error("Failed to decode token:", error);
        }
      }
  
      const historyData = {
        ...values,
        physician: loggedInPhysicianId,
        patient: patientHistoryId,
      };
      
      setIsLoading(true);
      const response = await axios.post(
        `https://${process.env.REACT_APP_API_URL}/api/patient/save-patient-history`,
        historyData
      );
      onSaveComplete();
      toast.success("Patient history saved successfully");
    } catch (error) {
      console.error("Error saving patient history:", error);
    } finally {
      setIsLoading(false);
      submitRef.current=false;
      handleSaveHistory.isSubmitting = false; // Reset the flag
    }
  };

  const handleCheckboxChangeMedication = (medication) => {
    setMedications((prev) => ({
      ...prev,
      [medication]: { ...prev[medication], used: !prev[medication]?.used },
    }));
  };

  const handleInputChangeMedication = (medication, field, value) => {
    setMedications((prev) => ({
      ...prev,
      [medication]: { ...prev[medication], [field]: value },
    }));
  };
  const handleCheckboxChange = (therapy, field = "used") => {
    setBiologicTherapies((prev) => ({
      ...prev,
      [therapy]: { ...prev[therapy], [field]: !prev[therapy][field] },
    }));
  };

  const handleInputChange = (therapy, field, value) => {
    setBiologicTherapies((prev) => ({
      ...prev,
      [therapy]: { ...prev[therapy], [field]: value },
    }));
  };

  const handleUpdateHistory = async (values) => {
    try {
      const token = localStorage.getItem("token");

      let loggedInPhysicianId = null;

      if (token) {
        try {
          const decodedToken = jwtDecode(token);
          loggedInPhysicianId = decodedToken.user.id; // Access the user ID
        } catch (error) {
          console.error("Failed to decode token:", error);
        }
      }
      const historyData = {
        ...values,
        physician: loggedInPhysicianId,
        patient: patientHistoryId,
      };
      setIsLoading(true);
      const response = await axios.put(
        `https://${process.env.REACT_APP_API_URL}/api/patient/edit-patient-history/${values._id}`,
        historyData
      );
      setIsLoading(false);
      toast.success("Patient history updated successfully");
      setFormHistoryData({
        SymptomDuration: {
          Duration: "",
          ContinuousOrIntermittent: "",
        },
        PreviousTreatments: {
          FreeTextField: "",
        },
        Medications: {
          OralCorticosteroids: {
            Used: false,
            NumberOfCyclesPerYear: "",
            ActiveIngredient: "",
          },
          TopicalCorticosteroids: {
            Used: false,
            NumberOfMonthsPerYear: "",
            ActiveIngredient: "",
          },
          Antihistamines: {
            Used: false,
          },
          Decongestants: {
            Used: false,
          },
          CorticosteroidsRetard: {
            Used: false,
          },
          TopicalCorticosteroidsAntihistamines: {
            Used: false,
          },
          LeukotrieneAntagonists: {
            Used: false,
          },
        },
        HadSurgicalIntervention: false,
        SurgicalInterventions: [
          {
            ProcedureType: "",
            HistologicalReport: "",
          },
        ],
        BiologicTherapies: {
          Mepolizumab: {
            used: false,
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
          Omalizumab: {
            used: false,
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
          Dupilumab: {
            used: false,
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
          Benralizumab: {
            used: false,
            currentTherapy: false,
            startYear: "",
            endYear: "",
            switch: "",
            switchReason: "",
          },
        },
        Comorbidities: {
          Asthma: false,
          Allergies: {
            Used: false,
            SpecificTypes: [],
          },
          CysticFibrosis: false,
          ChurgStraussSyndrome: false,
          HyperEosinophilia: false,
          ASASyndrome: false,
          ImmuneDeficiencies: false,
          Other: {
            Used: false,
            FreeTextField: "",
          },
        },
        MedicationUsage: {
          Aspirin: false,
          OtherNSAIDs: false,
          Other: {
            Used: false,
            FreeTextField: "",
          },
        },
        ObjectiveExamination: {
          AnteriorRhinoscopy: {
            PresenceOfPolyps: false,
            TurbinateHypertrophy: false,
            Secretions: false,
            Other: {
              Used: false,
              FreeTextField: "",
            },
          },
          NasalEndoscopy: {
            Dx: "",
            Sx: "",
          },
        },
        AdditionalInvestigations: {
          LundMcKayScore: "",
          Rhinomanometry: {
            AssessmentOfNasalAirflow: false,
            Results: "",
          },
          OssTest: "",
          SNOT: {
            needToBlowNose: 0,
            sneezing: 0,
            runnyNose: 0,
            cough: 0,
            postNasalDischarge: 0,
            thickNasalDischarge: 0,
            earFullness: 0,
            dizziness: 0,
            earPainPressure: 0,
            facialPainPressure: 0,
            difficultyFallingAsleep: 0,
            wakingUpAtNight: 0,
            lackOfGoodSleep: 0,
            wakingUpTired: 0,
            fatigueDuringDay: 0,
            reducedProductivity: 0,
            reducedConcentration: 0,
            frustratedIrritable: 0,
            sad: 0,
            embarrassed: 0,
            senseOfTasteSmell: 0,
            nasalBlockageCongestion: 0,
          },
        },
        AdditionalInformation: {
          QualityOfLife: {
            ImpactOnDailyLife: "",
          },
          PatientExpectations: {
            TherapyGoals: {
              ImprovementOfSymptoms: false,
              ReductionInNeedForMedications: false,
              ReductionInNeedForSurgicalInterventions: false,
              Other: {
                Used: false,
                FreeTextField: "",
              },
            },
          },
        },
      });
    } catch (error) {
      setIsLoading(false);
      toast.error("Error saving patient history");
      console.error("Error saving patient history:", error);
    }
  };

  const handleSNOTSave = (values) => {
    setFormHistoryData({
      ...formHistoryData,
      AdditionalInvestigations: {
        ...formHistoryData.AdditionalInvestigations,
        SNOT: values,
      },
    });
    // setSnotData(values); // Save the SNOT-22 values in the parent state
  };

  const handleCloseModal = () => {
    setFormHistoryData({
      SymptomDuration: {
        Duration: "",
        ContinuousOrIntermittent: "",
      },
      PreviousTreatments: {
        FreeTextField: "",
      },
      Medications: {
        OralCorticosteroids: {
          Used: false,
          NumberOfCyclesPerYear: "",
          ActiveIngredient: "",
        },
        TopicalCorticosteroids: {
          Used: false,
          NumberOfMonthsPerYear: "",
          ActiveIngredient: "",
        },
        Antihistamines: {
          Used: false,
        },
        Decongestants: {
          Used: false,
        },
        CorticosteroidsRetard: {
          Used: false,
        },
        TopicalCorticosteroidsAntihistamines: {
          Used: false,
        },
        LeukotrieneAntagonists: {
          Used: false,
        },
      },
      HadSurgicalIntervention: false,
      SurgicalInterventions: [
        {
          ProcedureType: "",
          HistologicalReport: "",
        },
      ],
      BiologicTherapies: {
        Mepolizumab: {
          used: false,
          currentTherapy: false,
          startYear: "",
          endYear: "",
          switch: "",
          switchReason: "",
        },
        Omalizumab: {
          used: false,
          currentTherapy: false,
          startYear: "",
          endYear: "",
          switch: "",
          switchReason: "",
        },
        Dupilumab: {
          used: false,
          currentTherapy: false,
          startYear: "",
          endYear: "",
          switch: "",
          switchReason: "",
        },
        Benralizumab: {
          used: false,
          currentTherapy: false,
          startYear: "",
          endYear: "",
          switch: "",
          switchReason: "",
        },
      },
      Comorbidities: {
        Asthma: false,
        Allergies: {
          Used: false,
          SpecificTypes: [],
        },
        CysticFibrosis: false,
        ChurgStraussSyndrome: false,
        HyperEosinophilia: false,
        ASASyndrome: false,
        ImmuneDeficiencies: false,
        Other: {
          Used: false,
          FreeTextField: "",
        },
      },
      MedicationUsage: {
        Aspirin: false,
        OtherNSAIDs: false,
        Other: {
          Used: false,
          FreeTextField: "",
        },
      },
      ObjectiveExamination: {
        AnteriorRhinoscopy: {
          PresenceOfPolyps: false,
          TurbinateHypertrophy: false,
          Secretions: false,
          Other: {
            Used: false,
            FreeTextField: "",
          },
        },
        NasalEndoscopy: {
          Dx: "",
          Sx: "",
        },
      },
      AdditionalInvestigations: {
        LundMcKayScore: "",
        Rhinomanometry: {
          AssessmentOfNasalAirflow: false,
          Results: "",
        },
        OssTest: "",
        SNOT: {
          needToBlowNose: 0,
          sneezing: 0,
          runnyNose: 0,
          cough: 0,
          postNasalDischarge: 0,
          thickNasalDischarge: 0,
          earFullness: 0,
          dizziness: 0,
          earPainPressure: 0,
          facialPainPressure: 0,
          difficultyFallingAsleep: 0,
          wakingUpAtNight: 0,
          lackOfGoodSleep: 0,
          wakingUpTired: 0,
          fatigueDuringDay: 0,
          reducedProductivity: 0,
          reducedConcentration: 0,
          frustratedIrritable: 0,
          sad: 0,
          embarrassed: 0,
          senseOfTasteSmell: 0,
          nasalBlockageCongestion: 0,
        },
      },
      AdditionalInformation: {
        QualityOfLife: {
          ImpactOnDailyLife: "",
        },
        PatientExpectations: {
          TherapyGoals: {
            ImprovementOfSymptoms: false,
            ReductionInNeedForMedications: false,
            ReductionInNeedForSurgicalInterventions: false,
            Other: {
              Used: false,
              FreeTextField: "",
            },
          },
        },
      },
    });
  };

  const UseEffectEffect = ({ submitForm }) => {
    useEffect(() => {
      if (submitRef.current) {
        submitForm();
      }
      return ()=>{
        submitRef.current=false
      }
    
    }, [submitRef.current]);
    
    return null;
  };

  return (
    <>
<MDBox pt={6} pb={3}>
<Grid container spacing={6}>
  <Grid item xs={12}>
    <Card>
      <MDBox
        mx={2}
        mt={-3}
        py={3}
        px={2}
        variant="gradient"
        bgColor="info"
        borderRadius="lg"
        coloredShadow="info"
      >
        <MDTypography variant="h6" color="white">
          Patient Medical History
     
        </MDTypography>
      </MDBox>

      <MDBox pt={3} pb={0}>
        <>
          <Formik
          initialValues={formHistoryData}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            // if (setSubmitting) {
            if (submitRef.current) {
              console.log("Form submission started");
              let data = {
                ...values,
                BiologicTherapies: biologicTherapies,
                Medications: medications,
              };
              delete data._id;
              handleSaveHistory(data).then(() => {
                setSubmitting(false);
              });
            }
          }}
          enableReinitialize={true}
          >

            {({  values, submitForm, isSubmitting  }) => {
              return (
                <Form className="form-container" style={{ margin: 31 }}>
                  <div className="form-section">
                    <h4>Symptom Duration</h4>
                    <div className="form-group">
                      <label>Duration (in months)</label>
                      <Field
                        type="number"
                        name="SymptomDuration.Duration"
                        className="form-field"
                        disabled={isDisabled}
                      />
                    </div>
                    <div className="form-group">
                      <label>Continuous or Intermittent:</label>
                      <Field
                        as="select"
                        name="SymptomDuration.ContinuousOrIntermittent"
                        className="form-field"
                        disabled={isDisabled}
                      >
                        <option value="">Select</option>
                        <option value="Continuous">Continuous</option>
                        <option value="Intermittent">
                          Intermittent
                        </option>
                      </Field>
                    </div>
                  </div>

                  <div className="form-section">
                    <h4>Medications (Farmaci)</h4>

                    {/* Oral Corticosteroids */}
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            medications?.OralCorticosteroids?.used
                          }
                          onChange={() =>
                            handleCheckboxChangeMedication(
                              "OralCorticosteroids"
                            )
                          }
                          disabled={isDisabled}
                        />
                        Oral Corticosteroids (Corticosteroidi per OS)
                      </label>
                      <br />
                      {medications?.OralCorticosteroids?.used && (
                        <>
                          <input
                            type="text"
                            placeholder="Number of Cycles per Year"
                            value={
                              medications?.OralCorticosteroids
                                ?.numberOfCyclesPerYear
                            }
                            onChange={(e) =>
                              handleInputChangeMedication(
                                "OralCorticosteroids",
                                "numberOfCyclesPerYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />
                          <input
                            type="text"
                            placeholder="Active Ingredient"
                            value={
                              medications?.OralCorticosteroids
                                ?.activeIngredient
                            }
                            onChange={(e) =>
                              handleInputChangeMedication(
                                "OralCorticosteroids",
                                "activeIngredient",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                            style={{ paddingLeft: "20px" }}
                          />
                        </>
                      )}
                    </div>

                    {/* Topical Corticosteroids */}
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            medications?.TopicalCorticosteroids?.used
                          }
                          onChange={() =>
                            handleCheckboxChangeMedication(
                              "TopicalCorticosteroids"
                            )
                          }
                          disabled={isDisabled}
                        />
                        Topical Corticosteroids (Corticosteroidi Topici)
                      </label>
                      <br />
                      {medications?.TopicalCorticosteroids?.used && (
                        <>
                          <input
                            type="text"
                            placeholder="Number of Months per Year"
                            value={
                              medications?.TopicalCorticosteroids
                                ?.numberOfMonthsPerYear
                            }
                            onChange={(e) =>
                              handleInputChangeMedication(
                                "TopicalCorticosteroids",
                                "numberOfMonthsPerYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />
                          <input
                            type="text"
                            placeholder="Active Ingredient"
                            value={
                              medications?.TopicalCorticosteroids
                                ?.activeIngredient
                            }
                            onChange={(e) =>
                              handleInputChangeMedication(
                                "TopicalCorticosteroids",
                                "activeIngredient",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />
                        </>
                      )}
                    </div>

                    {/* Antihistamines */}
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={medications?.Antihistamines?.used}
                          onChange={() =>
                            handleCheckboxChangeMedication(
                              "Antihistamines"
                            )
                          }
                          disabled={isDisabled}
                        />
                        Antihistamines (Antistaminici)
                      </label>
                    </div>

                    {/* Decongestants */}
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={medications?.Decongestants?.used}
                          onChange={() =>
                            handleCheckboxChangeMedication(
                              "Decongestants"
                            )
                          }
                          disabled={isDisabled}
                        />
                        Decongestants (Decongestionanti)
                      </label>
                    </div>

                    {/* Corticosteroids Retard */}
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            medications?.CorticosteroidsRetard?.used
                          }
                          onChange={() =>
                            handleCheckboxChangeMedication(
                              "CorticosteroidsRetard"
                            )
                          }
                          disabled={isDisabled}
                        />
                        Corticosteroids Retard
                      </label>
                    </div>

                    {/* Topical Corticosteroids + Antihistamines */}
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            medications
                              ?.TopicalCorticosteroidsAntihistamines
                              ?.used
                          }
                          onChange={() =>
                            handleCheckboxChangeMedication(
                              "TopicalCorticosteroidsAntihistamines"
                            )
                          }
                          disabled={isDisabled}
                        />
                        Topical Corticosteroids + Antihistamines
                      </label>
                    </div>

                    {/* Leukotriene Antagonists */}
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={
                            medications?.LeukotrieneAntagonists?.used
                          }
                          onChange={() =>
                            handleCheckboxChangeMedication(
                              "LeukotrieneAntagonists"
                            )
                          }
                          disabled={isDisabled}
                        />
                        Leukotriene Antagonists
                      </label>
                    </div>
                  </div>
                  <div className="form-section ">
                    <h4>
                      Surgical Interventions (Interventi Chirurgici)
                    </h4>
                    {/* Yes/No Selection */}
                    <div className="form-group">
                      <label>
                        Have you undergone any surgical procedures?
                      </label>
                      <Field
                        as="select"
                        name="HadSurgicalIntervention"
                        className="form-field"
                        disabled={isDisabled}
                      >
                        <option value={true}>Yes</option>
                        <option value={false}>No</option>
                      </Field>
                    </div>

                    {Boolean(`${(values.HadSurgicalIntervention === "true" || values.HadSurgicalIntervention === true) ? "true" : "" }`) && (
                      <FieldArray name="SurgicalInterventions">
                        {({ push, remove }) => (
                          <div>
                            {values?.SurgicalInterventions?.map(
                              (_, index) => (
                                <div
                                  key={index}
                                  className="form-subsection"
                                >
                                  {/* Procedure Type */}
                                  <Field
                                    as="select"
                                    name={`SurgicalInterventions.${index}.ProcedureType`}
                                    className="form-field"
                                    disabled={isDisabled}
                                  >
                                    <option value="" disable>
                                      Select Procedure
                                    </option>
                                    <option value="FESS">FESS</option>
                                    <option value="ESS">ESS</option>
                                    <option value="Polypectomy">
                                      Polypectomy
                                    </option>
                                  </Field>
                                  <br />
                                  <br />

                                  {/* Year of Procedure (Mandatory) */}
                                  <Field
                                    name={`SurgicalInterventions.${index}.Year`}
                                    placeholder="Year of Procedure"
                                    className="form-field"
                                    required
                                    disabled={isDisabled}
                                  />
                                  <br />
                                  <br />

                                  {/* Histological Report (Optional) */}
                                  <Field
                                    name={`SurgicalInterventions.${index}.HistologicalReport`}
                                    placeholder="Histological Report (Optional)"
                                    className="form-field"
                                    disabled={isDisabled}
                                  />

                                  <br />
                                  <br />

                                  <Button
                                    type="button"
                                    onClick={() => remove(index)}
                                    variant="outlined"
                                    color="primary"
                                    style={{
                                      color: "black",
                                      height: "5px",
                                    }}
                                    disabled={isDisabled}
                                  >
                                    Remove
                                  </Button>
                                </div>
                              )
                            )}

                            <Button
                              type="button"
                              onClick={() =>
                                push({
                                  HadSurgicalIntervention: false,
                                  ProcedureType: "",
                                  Year: "",
                                  HistologicalReport: "",
                                })
                              }
                              variant="outlined"
                              color="primary"
                              style={{ color: "black" }}
                              disabled={isDisabled}
                            >
                              Add Procedure
                            </Button>
                          </div>
                        )}
                      </FieldArray>
                    )}
                  </div>

                  <div className="form-section">
                    <h3>Biologic Therapies (Terapie Biologiche)</h3>

                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={biologicTherapies.Dupilumab.used}
                          onChange={() =>
                            handleCheckboxChange("Dupilumab")
                          }
                          disabled={isDisabled}
                        />
                        Dupilumab
                      </label>

                      {biologicTherapies.Dupilumab.used && (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                biologicTherapies.Dupilumab
                                  .currentTherapy
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Dupilumab",
                                  "currentTherapy"
                                )
                              }
                              disabled={isDisabled}
                            />
                            Current Therapy
                          </label>

                          <input
                            type="text"
                            placeholder="Start Year"
                            value={
                              biologicTherapies.Dupilumab.startYear
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Dupilumab",
                                "startYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <input
                            type="text"
                            placeholder="End Year"
                            value={biologicTherapies.Dupilumab.endYear}
                            onChange={(e) =>
                              handleInputChange(
                                "Dupilumab",
                                "endYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <select
                            value={biologicTherapies.Dupilumab.switch}
                            onChange={(e) =>
                              handleInputChange(
                                "Dupilumab",
                                "switch",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          >
                            <option value="">No switch</option>
                            <option value="Switched to Omalizumab">
                              Switched to Omalizumab
                            </option>
                            <option value="Switched to Benralizumab">
                              Switched to Benralizumab
                            </option>
                            <option value="Switched to Mepolizumab">
                              Switched to Mepolizumab
                            </option>
                          </select>

                          {biologicTherapies.Dupilumab.switch && (
                            <select
                              value={
                                biologicTherapies.Dupilumab.switchReason
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Dupilumab",
                                  "switchReason",
                                  e.target.value
                                )
                              }
                              className="form-field"
                              disabled={isDisabled}
                            >
                              <option value="">
                                Select reason for switch
                              </option>
                              <option value="Controllo dei sintomi">
                                Controllo dei sintomi
                              </option>
                              <option value="Effetti collaterali significativi">
                                Effetti collaterali significativi
                              </option>
                              <option value="Variazione biomarcatori">
                                Variazione biomarcatori
                              </option>
                              <option value="Disponibilità nuove opzioni terapeutiche">
                                Disponibilità nuove opzioni terapeutiche
                              </option>
                              <option value="Altro">Altro</option>
                            </select>
                          )}
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={biologicTherapies.Mepolizumab.used}
                          onChange={() =>
                            handleCheckboxChange("Mepolizumab")
                          }
                          disabled={isDisabled}
                        />
                        Mepolizumab
                      </label>

                      {biologicTherapies.Mepolizumab.used && (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                biologicTherapies.Mepolizumab
                                  .currentTherapy
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Mepolizumab",
                                  "currentTherapy"
                                )
                              }
                              disabled={isDisabled}
                            />
                            Current Therapy
                          </label>

                          <input
                            type="text"
                            placeholder="Start Year"
                            value={
                              biologicTherapies.Mepolizumab.startYear
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Mepolizumab",
                                "startYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <input
                            type="text"
                            placeholder="End Year"
                            value={
                              biologicTherapies.Mepolizumab.endYear
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Mepolizumab",
                                "endYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <select
                            value={biologicTherapies.Mepolizumab.switch}
                            onChange={(e) =>
                              handleInputChange(
                                "Mepolizumab",
                                "switch",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          >
                            <option value="">No switch</option>
                            <option value="Switched to Omalizumab">
                              Switched to Omalizumab
                            </option>
                            <option value="Switched to Benralizumab">
                              Switched to Benralizumab
                            </option>
                            <option value="Switched to Dupilumab">
                              Switched to Dupilumab
                            </option>
                          </select>

                          {biologicTherapies.Mepolizumab.switch && (
                            <select
                              value={
                                biologicTherapies.Mepolizumab
                                  .switchReason
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Mepolizumab",
                                  "switchReason",
                                  e.target.value
                                )
                              }
                              className="form-field"
                              disabled={isDisabled}
                            >
                              <option value="">
                                Select reason for switch
                              </option>
                              <option value="Controllo dei sintomi">
                                Controllo dei sintomi
                              </option>
                              <option value="Effetti collaterali significativi">
                                Effetti collaterali significativi
                              </option>
                              <option value="Variazione biomarcatori">
                                Variazione biomarcatori
                              </option>
                              <option value="Disponibilità nuove opzioni terapeutiche">
                                Disponibilità nuove opzioni terapeutiche
                              </option>
                              <option value="Altro">Altro</option>
                            </select>
                          )}
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={biologicTherapies.Omalizumab.used}
                          onChange={() =>
                            handleCheckboxChange("Omalizumab")
                          }
                          disabled={isDisabled}
                        />
                        Omalizumab
                      </label>

                      {biologicTherapies.Omalizumab.used && (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                biologicTherapies.Omalizumab
                                  .currentTherapy
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Omalizumab",
                                  "currentTherapy"
                                )
                              }
                              disabled={isDisabled}
                            />
                            Current Therapy
                          </label>

                          <input
                            type="text"
                            placeholder="Start Year"
                            value={
                              biologicTherapies.Omalizumab.startYear
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Omalizumab",
                                "startYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <input
                            type="text"
                            placeholder="End Year"
                            value={biologicTherapies.Omalizumab.endYear}
                            onChange={(e) =>
                              handleInputChange(
                                "Omalizumab",
                                "endYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <select
                            value={biologicTherapies.Omalizumab.switch}
                            onChange={(e) =>
                              handleInputChange(
                                "Omalizumab",
                                "switch",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          >
                            <option value="">No switch</option>
                            <option value="Switched to Omalizumab">
                              Switched to Omalizumab
                            </option>
                            <option value="Switched to Benralizumab">
                              Switched to Benralizumab
                            </option>
                            <option value="Switched to Mepolizumab">
                              Switched to Mepolizumab
                            </option>
                          </select>

                          {biologicTherapies.Omalizumab.switch && (
                            <select
                              value={
                                biologicTherapies.Omalizumab
                                  .switchReason
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Omalizumab",
                                  "switchReason",
                                  e.target.value
                                )
                              }
                              className="form-field"
                              disabled={isDisabled}
                            >
                              <option value="">
                                Select reason for switch
                              </option>
                              <option value="Controllo dei sintomi">
                                Controllo dei sintomi
                              </option>
                              <option value="Effetti collaterali significativi">
                                Effetti collaterali significativi
                              </option>
                              <option value="Variazione biomarcatori">
                                Variazione biomarcatori
                              </option>
                              <option value="Disponibilità nuove opzioni terapeutiche">
                                Disponibilità nuove opzioni terapeutiche
                              </option>
                              <option value="Altro">Altro</option>
                            </select>
                          )}
                        </>
                      )}
                    </div>
                    <div className="form-group">
                      <label>
                        <input
                          type="checkbox"
                          checked={biologicTherapies.Benralizumab.used}
                          onChange={() =>
                            handleCheckboxChange("Benralizumab")
                          }
                          disabled={isDisabled}
                        />
                        Benralizumab
                      </label>

                      {biologicTherapies.Benralizumab.used && (
                        <>
                          <label>
                            <input
                              type="checkbox"
                              checked={
                                biologicTherapies.Benralizumab
                                  .currentTherapy
                              }
                              onChange={() =>
                                handleCheckboxChange(
                                  "Benralizumab",
                                  "currentTherapy"
                                )
                              }
                              disabled={isDisabled}
                            />
                            Current Therapy
                          </label>

                          <input
                            type="text"
                            placeholder="Start Year"
                            value={
                              biologicTherapies.Benralizumab.startYear
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Benralizumab",
                                "startYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <input
                            type="text"
                            placeholder="End Year"
                            value={
                              biologicTherapies.Benralizumab.endYear
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Benralizumab",
                                "endYear",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          />

                          <select
                            value={
                              biologicTherapies.Benralizumab.switch
                            }
                            onChange={(e) =>
                              handleInputChange(
                                "Benralizumab",
                                "switch",
                                e.target.value
                              )
                            }
                            className="form-field"
                            disabled={isDisabled}
                          >
                            <option value="">No switch</option>
                            <option value="Switched to Omalizumab">
                              Switched to Omalizumab
                            </option>
                            <option value="Switched to Benralizumab">
                              Switched to Benralizumab
                            </option>
                            <option value="Switched to Mepolizumab">
                              Switched to Mepolizumab
                            </option>
                          </select>

                          {biologicTherapies.Benralizumab.switch && (
                            <select
                              value={
                                biologicTherapies.Benralizumab
                                  .switchReason
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "Benralizumab",
                                  "switchReason",
                                  e.target.value
                                )
                              }
                              className="form-field"
                              disabled={isDisabled}
                            >
                              <option value="">
                                Select reason for switch
                              </option>
                              <option value="Controllo dei sintomi">
                                Controllo dei sintomi
                              </option>
                              <option value="Effetti collaterali significativi">
                                Effetti collaterali significativi
                              </option>
                              <option value="Variazione biomarcatori">
                                Variazione biomarcatori
                              </option>
                              <option value="Disponibilità nuove opzioni terapeutiche">
                                Disponibilità nuove opzioni terapeutiche
                              </option>
                              <option value="Altro">Altro</option>
                            </select>
                          )}
                        </>
                      )}
                    </div>

                    {/* Repeat for Omalizumab, Dupilumab, Benralizumab */}
                  </div>

                  <div className="form-section">
                    <h4>Comorbidities (Comorbidità)</h4>
                    <div className="form-group">
                      <label>
                        <Field
                          type="checkbox"
                          name="Comorbidities.Asthma"
                          disabled={isDisabled}
                        />
                        Asthma (Asma)
                      </label>

                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "20px",
                            mb: 2,
                          }}
                        >
                          <FormControlLabel
                            control={
                              <Field
                                type="checkbox"
                                name="Comorbidities.Allergies.Used"
                                as={Checkbox}
                                label="Allergies (Allergie)"
                                disabled={isDisabled}
                              />
                            }
                            label="Allergies (Allergie)"
                          />
                        </Box>
                        {values?.Comorbidities?.Allergies?.Used && (
                          <FieldArray name="Comorbidities.Allergies.SpecificTypes">
                            {({ push, remove }) => (
                              <Box>
                                {values?.Comorbidities?.Allergies?.SpecificTypes?.map(
                                  (_, index) => (
                                    <Box
                                      key={index}
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        mb: 2,
                                      }}
                                    >
                                      <Field
                                        name={`Comorbidities.Allergies.SpecificTypes.${index}`}
                                        placeholder="Type of Allergy"
                                        as={TextField}
                                        fullWidth
                                        sx={{ mr: 2 }}
                                        disabled={isDisabled}
                                      />
                                      <Button
                                        type="button"
                                        onClick={() => remove(index)}
                                        variant="outlined"
                                        color="error"
                                        sx={{ height: "fit-content" }}
                                        style={{
                                          color: "black",
                                          border: "1px solid black",
                                        }}
                                        disabled={isDisabled}
                                      >
                                        Remove
                                      </Button>
                                    </Box>
                                  )
                                )}
                                {
                                  <Button
                                    type="button"
                                    onClick={() => push("")}
                                    variant="outlined"
                                    color="primary"
                                    style={{ color: "black" }}
                                    disabled={isDisabled}
                                  >
                                    Add Allergy
                                  </Button>
                                }
                              </Box>
                            )}
                          </FieldArray>
                        )}
                      </Grid>

                      <label>
                        <Field
                          type="checkbox"
                          name="Comorbidities.CysticFibrosis"
                          disabled={isDisabled}
                        />
                        Cystic Fibrosis (Fibrosi Cistica)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="Comorbidities.ChurgStraussSyndrome"
                          disabled={isDisabled}
                        />
                        Churg-Strauss Syndrome (Sindrome di
                        Churg-Strauss)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="Comorbidities.HyperEosinophilia"
                        disabled={isDisabled}
                        />
                        Hyper Eosinophilia (IperEosinofilia)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="Comorbidities.ASASyndrome"
                          disabled={isDisabled}
                        />
                        ASA Syndrome (Sindrome ASA)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="Comorbidities.ImmuneDeficiencies"
                          disabled={isDisabled}
                        />
                        Immune Deficiencies (Deficit Immunitari)
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          checked={comorbidities.Other.used}
                          onChange={() => {
                            setComorbidities((prev) => ({
                              ...prev,
                              Other: {
                                ...prev.Other,
                                used: !prev.Other.used,
                              },
                            }));
                          }}
                          disabled={isDisabled}
                        />
                        Other (Altro)
                      </label>
                      {comorbidities.Other.used && (
                        <div>
                          <select
                            value={comorbidities.Other.selectedOption} // Assuming you add this to your state
                            onChange={(e) => {
                              setComorbidities((prev) => ({
                                ...prev,
                                Other: {
                                  ...prev.Other,
                                  selectedOption: e.target.value, // Set the selected option here
                                },
                              }));
                            }}
                            className="form-field"
                            disabled={isDisabled}
                          >
                            <option value="">
                              Select a comorbidity
                            </option>
                            <option value="Cardiovascular Disease">
                              Cardiovascular Disease
                            </option>
                            <option value="Neurological Disease">
                              Neurological Disease
                            </option>
                            <option value="Psychiatric Disease">
                              Psychiatric Disease
                            </option>
                            <option value="Oncological Disease">
                              Oncological Disease
                            </option>
                            <option value="COPD-OSAS">COPD-OSAS</option>
                            <option value="Other">Other</option>
                          </select>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-section">
                    <h4>Medication Usage (Uso di Farmaci)</h4>
                    <div className="form-group">
                      <label>
                        <Field
                          type="checkbox"
                          name="MedicationUsage.Aspirin"
                          disabled={isDisabled}
                        />
                        Aspirin (Aspirina)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="MedicationUsage.OtherNSAIDs"
                          disabled={isDisabled}
                        />
                        Other NSAIDs (Altri FANS)
                      </label>
                      <label>
                        <input
                          type="checkbox"
                          checked={medicationUsage.Other.used}
                          onChange={() => {
                            setMedicationUsage((prev) => ({
                              ...prev,
                              Other: {
                                ...prev.Other,
                                used: !prev.Other.used,
                              },
                            }));
                          }}
                          disabled={isDisabled}
                        />
                        Other (Altro)
                      </label>
                      {medicationUsage.Other.used && (
                        <input
                          type="text"
                          placeholder="Specify other medications"
                          value={medicationUsage.Other.freeTextField}
                          onChange={(e) => {
                            setMedicationUsage((prev) => ({
                              ...prev,
                              Other: {
                                ...prev.Other,
                                freeTextField: e.target.value,
                              },
                            }));
                          }}
                          className="form-field"
                          disabled={isDisabled}
                        />
                      )}
                    </div>
                  </div>

                  <div className="form-section">
                    <h4>Objective Examination (Esame Obiettivo)</h4>
                    <div className="form-group">
                      <label>
                        <Field
                          type="checkbox"
                          name="ObjectiveExamination.AnteriorRhinoscopy.PresenceOfPolyps"
                        disabled={isDisabled}
                        />
                        Presence of Polyps (Presenza di Polipi)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="ObjectiveExamination.AnteriorRhinoscopy.TurbinateHypertrophy"
                          disabled={isDisabled}
                        />
                        Turbinate Hypertrophy (Ipertrofia dei Turbinati)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="ObjectiveExamination.AnteriorRhinoscopy.Secretions"
                         disabled={isDisabled}
                        />
                        Secretions (Secrezioni)
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          checked={anteriorRhinoscopy.Other.used}
                          onChange={() => {
                            setAnteriorRhinoscopy((prev) => ({
                              ...prev,
                              Other: {
                                ...prev.Other,
                                used: !prev.Other.used,
                              },
                            }));
                          }}
                          disabled={isDisabled}
                        />
                        Other (Altro)
                      </label>
                      {anteriorRhinoscopy.Other.used && (
                        <input
                          type="text"
                          placeholder="Specify other findings"
                          value={anteriorRhinoscopy.Other.freeTextField}
                          onChange={(e) => {
                            setAnteriorRhinoscopy((prev) => ({
                              ...prev,
                              Other: {
                                ...prev.Other,
                                freeTextField: e.target.value,
                              },
                            }));
                          }}
                          className="form-field"
                         disabled={isDisabled}
                        />
                      )}
                    </div>
                    <div className="form-group">
                      <h4>Nasal Endoscopy:</h4>
                      <br />
                      <label>NPS DX Value:</label>
                      <Field
                        as="select"
                        name="ObjectiveExamination.NasalEndoscopy.Dx"
                        className="form-field"
                        disabled={isDisabled}
                      >
                        {[...Array(5).keys()].map((score) => (
                          <option key={score} value={score}>
                            {score}
                          </option>
                        ))}
                      </Field>
                      <label>NPS SX Value:</label>
                      <Field
                        as="select"
                        name="ObjectiveExamination.NasalEndoscopy.Sx"
                        className="form-field"
                        disabled={isDisabled}
                      >
                        {[...Array(5).keys()].map((score) => (
                          <option key={score} value={score}>
                            {score}
                          </option>
                        ))}
                      </Field>
                      <label>Total (NPS SX + NPS DX) : </label>
                      <Field name="ObjectiveExamination.NasalEndoscopy.Total">
                        {({ field, form }) => {
                          return (
                            <input
                              {...field}
                              type="text"
                              value={
                                (parseInt(
                                  form.values?.ObjectiveExamination
                                    ?.NasalEndoscopy?.Dx
                                ) || 0) +
                                (parseInt(
                                  form.values?.ObjectiveExamination
                                    ?.NasalEndoscopy?.Sx
                                ) || 0)
                              }
                              readOnly
                              className="form-field"
                              disabled={isDisabled}
                            />
                          );
                        }}
                      </Field>
                    </div>
                  </div>

                  <div className="form-section">
                    <h4>Olfactory Assessment.</h4>
                    <div className="form-group">
                      <label>
                        Lund-McKay Score (Score di Lund-McKay):
                      </label>
                      <Field
                        as="select"
                        name="AdditionalInvestigations.LundMcKayScore"
                        className="form-field"
                        disabled={isDisabled}
                      >
                        {[...Array(25).keys()].map((score) => (
                          <option key={score} value={score}>
                            {score}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="form-group">
                      <label>
                        <Field
                          type="checkbox"
                          name="AdditionalInvestigations.Rhinomanometry.AssessmentOfNasalAirflow"
                          disabled={isDisabled}
                        />
                        Assessment of Nasal Airflow (Valutazione del
                        Flusso Aereo Nasale)
                      </label>
                      <Field
                        name="AdditionalInvestigations.Rhinomanometry.Results"
                        placeholder="Results"
                        className="form-field"
                        disabled={isDisabled}
                      />
                    </div>
                    <div className="form-group">
                      <label>OSS Test:</label>
                      <Field
                        as="select"
                        name="AdditionalInvestigations.OssTest"
                        className="form-field"
                        disabled={isDisabled}
                      >
                        <option value="">Select</option>
                        <option value="white">⬜</option>
                        <option value="green">🟩</option>
                        <option value="orange">🟧</option>
                        <option value="red">🟥</option>
                      </Field>
                      <br />
                    </div>
                    <div className="form-group">
                      <label>SNOT-22</label>

                      <SNOT22Dialog
                        onSave={handleSNOTSave}
                        values={
                          formHistoryData?.AdditionalInvestigations
                            ?.SNOT
                        }
                      />
                      <label>
                        Total SNOT-22 Value:{" "}
                        {calculateTotalScore(
                          formHistoryData?.AdditionalInvestigations
                            ?.SNOT
                        )}
                      </label>
                    </div>
                  </div>

                  <div className="form-section">
                    <h4>
                      Additional Information (Informazioni Aggiuntive)
                    </h4>

                    <div className="form-group">
                      <h4>
                        Patient Expectations (Aspettative del Paziente)
                      </h4>
                      <br />
                      <label>
                        <Field
                          type="checkbox"
                          name="AdditionalInformation.PatientExpectations.TherapyGoals.ImprovementOfSymptoms"
                          disabled={isDisabled}
                        />
                        Improvement of Symptoms (Miglioramento dei
                        Sintomi)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="AdditionalInformation.PatientExpectations.TherapyGoals.ReductionInNeedForMedications"
                          disabled={isDisabled}
                        />
                        Reduction in the Need for Medications (Riduzione
                        della Necessità di Farmaci)
                      </label>
                      <label>
                        <Field
                          type="checkbox"
                          name="AdditionalInformation.PatientExpectations.TherapyGoals.ReductionInNeedForSurgicalInterventions"
                          disabled={isDisabled}
                        />
                        Reduction in the Need for Surgical Interventions
                        (Riduzione della Necessità di Interventi
                        Chirurgici)
                      </label>
                      <br />
                      <label>
                        <input
                          type="checkbox"
                          checked={therapyGoals.Other.used}
                          onChange={() => {
                            setTherapyGoals((prev) => ({
                              ...prev,
                              Other: {
                                ...prev.Other,
                                used: !prev.Other.used,
                              },
                            }));
                          }}
                          disabled={isDisabled}
                        />
                        Other (Altro)
                      </label>
                      {therapyGoals.Other.used && (
                        <input
                          type="text"
                          placeholder="Specify other goals"
                          value={therapyGoals.Other.freeTextField}
                          onChange={(e) => {
                            setTherapyGoals((prev) => ({
                              ...prev,
                              Other: {
                                ...prev.Other,
                                freeTextField: e.target.value,
                              },
                            }));
                          }}
                          className="form-field"
                          disabled={isDisabled}
                        />
                      )}
                    </div>
                    <h4>
                      Previous Treatments (Trattamenti Precedenti)
                    </h4>
                    <Field
                      as="textarea"
                      name="PreviousTreatments.FreeTextField"
                      className="form-field"
                     disabled={isDisabled}
                    />
                    {/* <div className="form-section">
                  </div> */}
                    {/* <div className="form-section">
                {role === "physician" &&
          new URL(window.location.href).pathname !==
            "/hospital-wide-data" && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                paddingBottom: "20px",
                paddingRight:'88%'
              }}
            >
              <Button
                type="submit"
                variant="contained"
                color="primary"
                style={{ color: "white" }}
              >
                {isLoading ? (
                  <div
                    className="loader_wrapper"
                    style={{
                      height: "20px",
                      width: "40px",
                    }}
                  >
                    <CircularProgress
                      style={{
                        height: "20px",
                        width: "40px",
                      }}
                    />
                  </div>
                ) : patientHistory === undefined ? (
                  "Submit"
                ) : (
                  "Update"
                )}
              </Button>
            </div>
          )}
                </div> */}
                  </div>
                  {submitRef.current &&
                    <UseEffectEffect submitForm={submitForm} />}
                </Form>
              );
            }}
          </Formik>
        </>
      </MDBox>
    </Card>
  </Grid>
</Grid>
</MDBox>
    </>
  );
};

export default MDHistoryForm;
