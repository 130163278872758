/**
=========================================================
* Material Dashboard 2 React - v2.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ReportsBarChart from "examples/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "examples/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "examples/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";

// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import { useContext, useEffect } from "react";
import { AuthContext } from 'context';
import { useNavigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decoded = jwtDecode(token);
    return decoded.exp < Date.now() / 1000;
  } catch (error) {
    return true;
  }
};

function Dashboard() {
  const { appointments, procedures } = reportsLineChartData;
  const { userData: { role, token }, setUserData } = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenExpiration = () => {
      if (isTokenExpired(token)) {
        localStorage.removeItem('role');
        localStorage.removeItem('token');
        setUserData({ token: null, role: null });
        navigate('/authentication/sign-in');
      }
    };

    const intervalId = setInterval(checkTokenExpiration, 1000); // Check every second

    return () => clearInterval(intervalId);
  }, [token, navigate, setUserData]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="medical_services"
                title="Scheduled Appointments"
                count={281}
                percentage={{
                  color: "success",
                  amount: "+12%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="healing"
                title="Scheduled Procedures"
                count="125"
                percentage={{
                  color: "success",
                  amount: "+5%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="groups"
                title="Active Patients"
                count="9,302"
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="medical_information"
                title="New Diagnoses"
                count="57"
                percentage={{
                  color: "success",
                  amount: "+2%",
                  label: "since last month",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="Patients Seen"
                  description="Last Month Performance"
                  date="updated 4 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="Scheduled Appointments"
                  description={
                    <>
                      (<strong>+12%</strong>) increase since last month.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={appointments}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="Scheduled Procedures"
                  description="Last Month Performance"
                  date="just updated"
                  chart={procedures}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </DashboardLayout>
  );
}
export default Dashboard;
