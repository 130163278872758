import React, { useState, useEffect, useContext, useRef } from "react";
import {
  Card,
  Grid,
  Box,
  Button,
  Modal,
  MenuItem,
  TextField,
  InputAdornment,
  Typography,
} from "@mui/material";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import DataTable from "examples/Tables/DataTable";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import { AuthContext } from "context";
import { useNavigate } from "react-router-dom";
import MDReportModal from "components/MDReportModal/MDReportModal";
import MDHistoryModal from "components/MDHistoryModal/MDHistoryModal";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { jwtDecode } from "jwt-decode";
import { MdCloudDownload } from "react-icons/md";
import jsonToCsvExport from "json-to-csv-export";
import { MdAssignmentAdd } from "react-icons/md";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CircularProgress from "@mui/material/CircularProgress";
import toast from "react-hot-toast";
import { IoCreate } from "react-icons/io5";
import { Select, InputLabel, FormControl, FormHelperText } from "@mui/material";
import { IoMdArrowDropdown } from "react-icons/io";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import MDDelete from "components/MDDelete";
import AccountCircleRoundedIcon from "@mui/icons-material/AccountCircleRounded";
import MedicalInformationIcon from "@mui/icons-material/MedicalInformation";
import Tooltip from "@mui/material/Tooltip";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { FaPrint } from "react-icons/fa";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { CiCircleCheck } from "react-icons/ci";
import { useReactToPrint } from "react-to-print";
import ConsentFormPrint from "../consentFormPrint/ConsentFormPrint";
import { render } from "react-dom";
const headers = [
  "Serial No",
  "Name",
  "createdAt",
  "dob",
  "Address",
  "Gender",
  "updatedAt",
  "physician",
];

// const headers = [
//   "Serial No",
//   "Name",
//   "Mobile No",
//   "dob",
//   "Address",
//   "Gender",
//   "Blood Group",
//   "Amount",
//   "consent",
//   "consentToken",
//   "createdAt",
//   'updatedAt',
//   "physician"
// ];

const PatientsTables = () => {
  const [tableData, setTableData] = useState({ columns: [], rows: [] });
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [newPatientData, setNewPatientData] = useState({
    name: "",
    lastname: "",
    email: "",
    address: "",
    gender: "",
    dob: "",
    email: "",
    consent: "",
    consentToken: "",
  });

  const [editPatientData, setEditPatientData] = useState({});
  const [responseError, setResponseError] = useState("");
  const [successSB, setSuccessSB] = useState(false);
  const [errorSB, setErrorSB] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [editFormErrors, setEditFormErrors] = useState({});
  const {
    userData: { role, token },
  } = useContext(AuthContext);
  const [openPatientReportModal, setOpenPatientReportModal] = useState(false);
  const [openPatientHistoryModal, setOpenPatientHistoryModal] = useState(false);
  const [createdPatientId, setCreatedPatientId] = useState("");
  const [loggedInPhysicianId, setLoggedInPhysicianId] = useState("");
  const [medicalReportData, setMedicalReportData] = useState("");
  const [historyPatientId, setHistoryPatientId] = useState("");
  const [historyPatient, setHistoryPatient] = useState({});
  const [isPatientLoading, setIsPatientLoading] = useState(false);
  const [isAddPatientLoading, setIsAddPatientLoading] = useState(false);
  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deletePatientId, setDeletePatientId] = useState("");
  const [selectedHospital, setSelectedHospital] = useState("");
  const [hospitals, setHospitals] = useState([]);
  const contentRef = useRef(null);
  const consentApprove2 = useRef(false);
  const [consentApprove, setConsentApprove] = useState(false);
  const [searchPatient, setSearchPatient] = useState("");
  const reactToPrintFn = useReactToPrint({ contentRef });
  const [searhedData,setSearchedData]=useState({ columns: [], rows: [] })
  const [seachActive,setSeachActive] = useState(false)
  const consentButtonsRef = useRef(null);
  // const [originalRows, setOriginalRows] = useState(tableData?.rows);

  const navigate = useNavigate();

  // Function to open the PatientMedicalDetailsForm modal
  const handleOpenPatientReportModal = () => {
    setOpenPatientReportModal(true);
  };

  const handleClosePatientReportModal = () => {
    setOpenPatientReportModal(false);
  };

  const handleOpenPatientHistoryModal = () => {
    setOpenPatientHistoryModal(true);
  };

  const handleClosePatientHistoryModal = () => {
    setOpenPatientHistoryModal(false);
  };

  const handleHospitalChange = (value) => {
    setSelectedHospital(value);
    fetchPatients();
  };

  const handleDateChange = (date) => {
    setNewPatientData({ ...newPatientData, dob: date });

    // Validate date and remove error if valid
    if (date) {
      setFormErrors((prevErrors) => ({ ...prevErrors, dob: "" }));
    } else {
      setFormErrors((prevErrors) => ({
        ...prevErrors,
        dob: "Please select a valid Date of Birth",
      }));
    }
  };

  const handlePrintClick = () => {
    reactToPrintFn(); // Your print function logic

    // Toggle the visibility of the consent buttons
    if (consentButtonsRef.current) {
      consentButtonsRef.current.style.display =
        consentButtonsRef.current.style.display === "none" ? "flex" : "none";
    }
  };

  useEffect(() => {
    if (!token) {
      navigate("/authentication/sign-in");
    }
    fetchHospitals();
  }, []);

  const calculateAge = (dob) => {
    const today = new Date();
    const birthDate = new Date(dob);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const fetchPatients = () => {
    const token = localStorage.getItem("token");
    let userId = null;

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        userId = decodedToken.user.id;
        setLoggedInPhysicianId(userId);
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }

    setIsPatientLoading(true);

    fetch(
      `${
        role === "physician"
          ? `https://${process.env.REACT_APP_API_URL}/api/patient/get-all/${userId}`
          : `https://${process.env.REACT_APP_API_URL}/api/patient/filter-patients/${selectedHospital}`
      }`
    )
      .then((response) => response.json())
      .then((data) => {
        const patients = data.patients;
        let tempColumns;

        const renderElement = (bool) => {
          if (bool) {
            return <>2 but</>;
          } else {
            return <>No but</>;
          }
        };

        if (role === "admin") {
          tempColumns = ["sno", "age", ...Object.keys(patients[0] || {})]
            .map((key, index) => {
              if (
                [
                  "__v",
                  "physician",
                  "lastname",
                  "dob",
                  "email",
                  "consent",
                  "consentToken",
                  "consentTokenExpires",
                  "name",
                  "address",
                  "updatedAt",
                  "createdAt",
                  "_id",
                ].includes(key)
              )
                return null;
              return {
                Header:
                  key === "sno"
                    ? "Serial No"
                    : key.charAt(0).toUpperCase() + key.slice(1),
                accessor: key,
                width: "14%",
                align: "left",
              };
            })
            .filter((column) => column !== null);

          if (role === "physician" || "admin") {
            tempColumns.push({
              Header: "Consent",
              accessor: "actions",
              width: "14%",
              align: "center",
            });
          }
        } else {
          tempColumns = ["sno", ...Object.keys(patients[0] || {})]
            .map((key, index) => {
              if (
                [
                  "__v",
                  "physician",
                  "lastname",
                  "dob",
                  "email",
                  "consent",
                  "consentToken",
                  "consentTokenExpires",
                  "updatedAt",
                  "createdAt",
                  "_id",
                ].includes(key)
              )
                return null;
              return {
                Header:
                  key === "sno"
                    ? "Serial No"
                    : key.charAt(0).toUpperCase() + key.slice(1),
                accessor: key,
                width: "14%",
                align: "left",
              };
            })
            .filter((column) => column !== null);

          if (role === "physician" || "admin") {
            tempColumns.push({
              Header: "Consent",
              accessor: "actions",
              width: "14%",
              align: "center",
            });
          }
        }

        let patientsRowsData = patients.map((d, i) => {
          const baseData = {
            sno: i + 1,
            ...d,
            _id: d._id,
            gender:
              (d.gender || "").charAt(0).toUpperCase() +
              (d.gender || "").slice(1).toLowerCase(),
            age: calculateAge(d.dob),
          };

          return baseData;
        });

        setTableData({
          columns: tempColumns,
          rows: patientsRowsData.map((row, index) => ({
            ...row,
            actions: (
              <MDBox display="flex" justifyContent="space-evenly">
                {row?.consent === "progress" ? (
                  <>
                    <span style={{ color: "#1a73e8", paddingTop: "8px" }}>
                      In Progress
                    </span>
                    <>
                      {role === "physician" && (
                        <Tooltip
                          title="Print Consent Form"
                          arrow
                          placement="top"
                        >
                          <MDButton>
                            <FaPrint onClick={handlePrintClick} />
                            <div style={{ display: "none" }}>
                              <ConsentFormPrint contentREf={contentRef} />
                            </div>
                          </MDButton>
                        </Tooltip>
                      )}
                    </>

                    {/* Use ref to control the visibility of Approve and Reject buttons */}
                    <div
                      ref={consentButtonsRef}
                      style={{
                        display: "none",
                        justifyContent: "space-evenly",
                      }}
                    >
                      <Tooltip title="Approve Consent" arrow placement="top">
                        <MDButton
                          onClick={() => updateConsent(row._id, "accept")}
                        >
                          <CiCircleCheck style={{ color: "green" }} />
                        </MDButton>
                      </Tooltip>

                      <Tooltip title="Reject Consent" arrow placement="top">
                        <MDButton
                          onClick={() => updateConsent(row._id, "reject")}
                        >
                          <IoIosCloseCircleOutline style={{ color: "red" }} />
                        </MDButton>
                      </Tooltip>
                    </div>
                  </>
                ) : row?.consent === "reject" ? (
                  <span style={{ color: "red" }}>Rejected</span>
                ) : role === "physician" ? (
                  <>
                    <Tooltip title="Patient Information Record" arrow placement="top">
                      <MDButton onClick={() => navigate(`/patient/${row._id}`)}>
                        <VisibilityIcon
                          style={{ color: "grey" }}
                        ></VisibilityIcon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip title="Edit Patient" arrow placement="top">
                      <MDButton onClick={() => handleEditOpen(row)}>
                        <IoCreate />
                      </MDButton>
                    </Tooltip>
                    <Tooltip title="Delete Patient" arrow placement="top">
                      <MDButton
                        onClick={() => {
                          setDeleteDialog(true);
                          setDeletePatientId(row._id);
                        }}
                      >
                        <Icon color="error">delete</Icon>
                      </MDButton>
                    </Tooltip>
                    {/* <Tooltip title="Open Medical Report" arrow placement="top">
                      <MDButton onClick={() => handleOpenReport(row._id)}>
                        <MedicalInformationIcon
                          style={{ color: "grey" }}
                        ></MedicalInformationIcon>
                      </MDButton>
                    </Tooltip> */}
                    {/* <Tooltip title="Open Medical History" arrow placement="top">
                      <MDButton onClick={() => handleAddHistory(row._id)}>
                        <MdAssignmentAdd />
                      </MDButton>
                    </Tooltip> */}
                    {/* <Tooltip
                      title="Export Medical History"
                      arrow
                      placement="top"
                    >
                      <MDButton onClick={() => handledownloadExcel(row._id)}>
                        <ArrowDownwardIcon />
                      </MDButton>
                    </Tooltip> */}
                  </>
                ) : (
                  <>
                    <Tooltip title="Open Medical Report" arrow placement="top">
                      <MDButton onClick={() => handleOpenReport(row._id)}>
                        <VisibilityIcon
                          style={{ color: "grey" }}
                        ></VisibilityIcon>
                      </MDButton>
                    </Tooltip>
                    <Tooltip
                      title="Download Medical Report"
                      arrow
                      placement="top"
                    >
                      <MDButton onClick={() => handleDownloadReport(row._id)}>
                        <MdCloudDownload style={{ color: "grey" }}>
                          {" "}
                        </MdCloudDownload>
                      </MDButton>
                    </Tooltip>
                  </>
                )}
              </MDBox>
            ),
          })),
        });

        setIsPatientLoading(false);
      })
      .catch(() => {
        setIsPatientLoading(false);
        setResponseError("Error fetching data");
        setErrorSB(true);
      });
  };

  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setNewPatientData({});
    setFormErrors({});
  };

  const handleEditOpen = (patient) => {
    setEditPatientData(patient);
    setEditOpen(true);
  };

  const handleEditClose = () => {
    setEditOpen(false);
    setEditFormErrors({});
  };

  const validateForm = (data) => {
    const errors = {};

    // Regular expression for validating names and address (no numbers or special characters)
    const nameAddressRegex = /^[A-Za-z\s]+$/; // Allows only letters and spaces

    // Email pattern: First letter must be lowercase, valid format
    const emailPattern = /^[a-z][\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const invalidPatterns = /(\.\.)|(\.[a-zA-Z]{2,}\.[a-zA-Z]{2,})$/;

    // Validate First Name (empty, whitespace, numeric, or special characters)
    if (!data.name || data.name.trim() === "") {
      errors.fname = "First Name is required";
    } else if (!nameAddressRegex.test(data.name)) {
      errors.fname =
        "First Name should not contain numbers or special characters";
    }

    // Validate Last Name (empty, whitespace, numeric, or special characters)
    if (!data.lastname || data.lastname.trim() === "") {
      errors.lname = "Last Name is required";
    } else if (!nameAddressRegex.test(data.lastname)) {
      errors.lname =
        "Last Name should not contain numbers or special characters";
    }

    // Validate Email (empty, whitespace, or invalid format)
    const { email } = data; // Destructure email from data
    if (!email || email.trim() === "") {
      errors.email = "Email is required";
    } else if (!emailPattern.test(email)) {
      errors.email = "invalid Email";
    } else if (invalidPatterns.test(email)) {
      errors.email = "Email contains invalid patterns";
    }

    // Validate Address (empty, whitespace, numeric, or special characters)
    if (!data.address || data.address.trim() === "") {
      errors.address = "City of Residence is required";
    } else if (!nameAddressRegex.test(data.address)) {
      errors.address =
        "Address should not contain numbers or special characters";
    }

    // Validate Gender (empty or whitespace)
    if (!data.gender || data.gender.trim() === "") {
      errors.gender = "Gender is required";
    }

    // Validate Date of Birth (empty or whitespace)
    if (!data.dob) {
      errors.dob = "Date of Birth is required";
    }

    return errors;
  };

  const handleDeleteDialog = (value) => {
    setDeleteDialog(value);
  };

  // const handleChange = (e) => {
  //   setNewPatientData((prevState) => ({
  //     ...prevState,
  //     [e.target.name]: e.target.value,
  //   }));
  //   setFormErrors((prevState) => ({ ...prevState, [e.target.name]: "" }));
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setNewPatientData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Regular expression for validating names and address (no numbers or special characters)
    const nameAddressRegex = /^[A-Za-z\s]+$/; // Allows only letters and spaces

    // Email pattern: First letter must be lowercase, valid format
    const emailPattern = /^[a-z][\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const invalidPatterns = /(\.\.)|(\.[a-zA-Z]{2,}\.[a-zA-Z]{2,})$/;

    // Validate Email
    if (name === "email") {
      if (!value.trim()) {
        setFormErrors((prevState) => ({
          ...prevState,
          email: "Email is required",
        }));
      } else if (!emailPattern.test(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          email: "invalid Email",
        }));
      } else if (invalidPatterns.test(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          email:
            "Email contains invalid patterns (e.g., double dots or invalid domain)",
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          email: "",
        }));
      }
    }

    // Validate First Name
    if (name === "name") {
      if (!value.trim()) {
        setFormErrors((prevState) => ({
          ...prevState,
          fname: "First Name is required",
        }));
      } else if (!nameAddressRegex.test(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          fname: "First Name should not contain numbers or special characters",
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          fname: "",
        }));
      }
    }

    // Validate Last Name
    if (name === "lastname") {
      if (!value.trim()) {
        setFormErrors((prevState) => ({
          ...prevState,
          lname: "Last Name is required",
        }));
      } else if (!nameAddressRegex.test(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          lname: "Last Name should not contain numbers or special characters",
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          lname: "",
        }));
      }
    }

    // Validate Address
    if (name === "address") {
      if (!value.trim()) {
        setFormErrors((prevState) => ({
          ...prevState,
          address: "City of Residence is required",
        }));
      } else if (!nameAddressRegex.test(value)) {
        setFormErrors((prevState) => ({
          ...prevState,
          address: "Address should not contain numbers or special characters",
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          address: "",
        }));
      }
    }

    // Validate Gender
    if (name === "gender") {
      if (!value.trim()) {
        setFormErrors((prevState) => ({
          ...prevState,
          gender: "Gender is required",
        }));
      } else {
        setFormErrors((prevState) => ({
          ...prevState,
          gender: "",
        }));
      }
    }
  };

  const fetchHospitals = () => {
    setIsPatientLoading(true);
    fetch(`https://${process.env.REACT_APP_API_URL}/api/physician/recommend-hospital`)
      .then((res) => res.json())
      .then((data) => {
        if (data.success) {
          setIsPatientLoading(false);
          setHospitals(data.hospitals);
          setSelectedHospital(data.hospitals[0]);
        }
      })
      .catch((error) => {
        setIsPatientLoading(false);
        console.error("Error fetching hospitals:", error);
      });
  };

  useEffect(() => {
    if (selectedHospital) {
      fetchPatients();
    }
  }, [selectedHospital]);

  const handleEditChange = (e) => {
    const { name, value } = e.target;

    setEditPatientData((prevState) => ({
      ...prevState,
      [name]: value,
    }));

    // Regular expression for validating names and address (no numbers or special characters)
    const nameAddressRegex = /^[A-Za-z\s]+$/; // Allows only letters and spaces

    // Email pattern: First letter must be lowercase, valid format
    const emailPattern = /^[a-z][\w.-]+@[a-zA-Z\d.-]+\.[a-zA-Z]{2,}$/;
    const invalidPatterns = /(\.\.)|(\.[a-zA-Z]{2,}\.[a-zA-Z]{2,})$/;

    // Validate Email
    if (name === "email") {
      if (!value.trim()) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          email: "Email is required",
        }));
      } else if (!emailPattern.test(value)) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          email: "invalid Email",
        }));
      } else if (invalidPatterns.test(value)) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          email:
            "Email contains invalid patterns (e.g., double dots or invalid domain)",
        }));
      } else {
        setEditFormErrors((prevState) => ({
          ...prevState,
          email: "",
        }));
      }
    }

    // Validate First Name
    if (name === "name") {
      if (!value.trim()) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          fname: "First Name is required",
        }));
      } else if (!nameAddressRegex.test(value)) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          fname: "First Name should not contain numbers or special characters",
        }));
      } else {
        setEditFormErrors((prevState) => ({
          ...prevState,
          fname: "",
        }));
      }
    }

    // Validate Last Name
    if (name === "lastname") {
      if (!value.trim()) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          lname: "Last Name is required",
        }));
      } else if (!nameAddressRegex.test(value)) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          lname: "Last Name should not contain numbers or special characters",
        }));
      } else {
        setEditFormErrors((prevState) => ({
          ...prevState,
          lname: "",
        }));
      }
    }

    // Validate Address
    if (name === "address") {
      if (!value.trim()) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          address: "City of Residence is required",
        }));
      } else if (!nameAddressRegex.test(value)) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          address: "Address should not contain numbers or special characters",
        }));
      } else {
        setEditFormErrors((prevState) => ({
          ...prevState,
          address: "",
        }));
      }
    }

    // Validate Gender
    if (name === "gender") {
      if (!value.trim()) {
        setEditFormErrors((prevState) => ({
          ...prevState,
          gender: "Gender is required",
        }));
      } else {
        setEditFormErrors((prevState) => ({
          ...prevState,
          gender: "",
        }));
      }
    }
  };

  const handleAddPatient = () => {
    const errors = validateForm(newPatientData);
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      return;
    }

    setMedicalReportData("");

    const token = localStorage.getItem("token");

    let userId = null;

    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        userId = decodedToken.user.id;
        setLoggedInPhysicianId(userId);
      } catch (error) {
        console.error("Failed to decode token:", error);
      }
    }
    const dataBody = {
      ...newPatientData,
      physician: userId,
      consent: "progress",
    };

    setIsAddPatientLoading(true);
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/create`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data?.success) {
          setCreatedPatientId(data.patient._id);
          setSuccessSB(true);
          fetchPatients();
          handleClose();
          setNewPatientData({});
          // handleOpenPatientReportModal();
          setIsAddPatientLoading(false);
          toast.success(
            "Patient Added Succesfully and an Email Has been sent to you for consent"
          );
        } else {
          setResponseError("Error adding patient");
          setErrorSB(true);
          if (data?.errors?.map((e) => e.path) == undefined) {
            toast.error(data.message);
          } else {
            toast.error(`Invalid:- ${data?.errors?.map((e) => e.path)}`);
          }
          setIsAddPatientLoading(false);
        }
      })
      .catch((err) => {
        setIsAddPatientLoading(false);
        setResponseError("Error adding patient");
        setErrorSB(true);
      });
  };

  const handleEditPatient = () => {
    const errors = validateForm(editPatientData);
    if (Object.keys(errors).length > 0) {
      setEditFormErrors(errors);
      return;
    }
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/update/${editPatientData._id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(editPatientData),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessSB(true);
          fetchPatients();
          handleEditClose();
          toast.success("Patient Updated Succesfully");
        } else {
          setResponseError(data.message || "Error updating patient");
          toast.error(data.message);
          setErrorSB(true);
        }
      })
      .catch(() => {
        setResponseError("Error updating patient");
        setErrorSB(true);
      });
  };

  const handleDeletePatient = () => {
    setDeleteDialog(false);
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/delete/${deletePatientId}`, {
      method: "DELETE",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessSB(true);
          setTableData((prevState) => ({
            ...prevState,
            rows: prevState.rows.filter((row) => row._id !== deletePatientId),
          }));
          toast.success("Patient Deleted Succesfully");
        } else {
          setResponseError(data.message || "Error deleting patient");
          toast.error(data.message);
          setErrorSB(true);
        }
      })
      .catch(() => {
        setResponseError("Error deleting patient");
        setErrorSB(true);
      });
  };

  const handleAddHistory = (id) => {
    setHistoryPatientId(id);
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/patient-history/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setSuccessSB(true);
          setHistoryPatient(data.patientHistory[0]);
          setOpenPatientHistoryModal(true);
          // setMedicalReportData(data.medicalReports[0])
          // setOpenPatientReportModal(true);
        } else {
          setOpenPatientHistoryModal(true);
        }
      })
      .catch(() => {
        setOpenPatientHistoryModal(true);
      });
  };

  const handleOpenReport = (id) => {
    setCreatedPatientId(id);
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/medical-report/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          // setSuccessSB(true);
          setMedicalReportData(data.medicalReports[0]);
          setOpenPatientReportModal(true);
        } else {
          setResponseError(data.message || "Error getting patient report");
          setErrorSB(true);
          setMedicalReportData("");
          setOpenPatientReportModal(true);
        }
      })
      .catch(() => {
        setMedicalReportData("");
        setOpenPatientReportModal(true);
        setResponseError("Error getting patient report");
        setErrorSB(true);
      });
  };

  const handleChangeSearchPatient = (e) => {
    if(e.target.value===""){
      setSeachActive(false)
    }
    setSearchPatient(e.target.value);
    setSeachActive(true)
    console.log(searchPatient, "searchPatient");
    let searchTerm = e.target.value.toLowerCase();

    let filteredRows = tableData?.rows.filter((row) => {
      const fullName = `${row.name} ${row.lastname}`.toLowerCase();
      return (
        row.name.toLowerCase().includes(searchTerm) ||
        row.lastname.toLowerCase().includes(searchTerm) ||
        fullName.includes(searchTerm)
      );
    });
    setSearchedData((prev) => ({ columns: tableData.columns, rows: filteredRows}));
  };

  const handleAddPatientReports = (data) => {
    const dataBody = {
      ...data,
      physicianId: loggedInPhysicianId,
      patientId: createdPatientId,
    };
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/create-patient-report`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessSB(true);
          toast.success("Added Patient Reports Succesfully");
        } else {
          setResponseError(
            data.message || "Error adding patient medical report data"
          );
          toast.error("Error Adding Report");
          setErrorSB(true);
        }
      })
      .catch(() => {
        setResponseError("Error adding patient");
        toast.error("Error Adding Report");
        setErrorSB(true);
      });
  };

  const handleEditPatientReports = (data) => {
    const dataBody = { ...data, reportId: data._id };
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/update-patient-report`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(dataBody),
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          setSuccessSB(true);
          toast.success("Patient Report Updated succesully");
        } else {
          setResponseError(
            data.message || "Error Updating patient medical report data"
          );
          toast.error("Error Updating patirnt report");
          setErrorSB(true);
        }
      })
      .catch(() => {
        setResponseError("Error Updating patient");
        setErrorSB(true);
      });
  };

  const handleDownloadReport = (id) => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/medical-report/${id}`, {
      method: "GET",
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.success) {
          const dataToConvert = {
            data: data.medicalReports,
            filename: "medical_report",
            delimiter: ",",
            headers: [
              "nasalObstructionSeverity",
              "nasalObstructionLaterality",
              "rhinorrheaSecretion",
              "rhinorrheaCharacteristics",
              "reductionInSmell",
              "reductionInTaste",
              "painPresence",
              "painIntensity",
              "painFrequency",
              "otherSymptoms",
              "snot22Score",
              "igeValue",
              "eosinophilCount",
              "nasalCytologyEosinophilCount",
              "tissueEosinophilCount",
            ],
          };
          toast.success("Report Downloaded Succesfully");
          jsonToCsvExport(dataToConvert);
        } else {
          setResponseError(data.message || "Error getting patient report");
          toast.error(data.message);
          setErrorSB(true);
        }
      })
      .catch((error) => {
        setResponseError("Error getting patient report");
        toast.error("Error getting patient report");
        setErrorSB(true);
      });
  };

  const handledownloadExcel = (id) => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/patient-history/download/${id}`, {
      method: "GET",
    })
      .then((response) => {
        if (!response.ok) {
          toast.error("Patient History Not Found");
          throw new Error("Network response was not ok ");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `patient_history_${id}.xlsx`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.log(error, "error");
        console.error("Error downloading the Excel file:", error);
      });
  };
  const updateConsent = (id, consentStatus) => {
    fetch(`https://${process.env.REACT_APP_API_URL}/api/patient/consent-update/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ consent: consentStatus }),
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to update consent");
        }
        return response.json();
      })
      .then((data) => {
        toast.success(`Consent ${consentStatus} successfully!`);
        fetchPatients();
        console.log(data); // You can update the UI state if needed
      })
      .catch((error) => {
        console.error("Error updating consent:", error);
        toast.error(`Failed to ${consentStatus} consent!`);
      });
  };

  // const renderRows = () => {
  //   return tableData.rows.map((row, index) => ({
  //     ...row,
  //     actions: (
  //       <MDBox display="flex" justifyContent="space-evenly">
  //         {row?.consent === "progress" ? (
  //           <>
  //             <span style={{ color: "#1a73e8", paddingTop: "8px" }}>
  //               In Progress
  //             </span>
  //             <></>
  //             {role === "physician" && (
  //               <Tooltip title="Print Consent Form" arrow placement="top">
  //                 <MDButton>
  //                   <FaPrint
  //                     onClick={() => {
  //                       reactToPrintFn();
  //                       setConsentApprove(true);
  //                     }}
  //                   />
  //                   <div style={{ display: "none" }}>
  //                     <ConsentFormPrint contentREf={contentRef} />
  //                   </div>
  //                 </MDButton>
  //               </Tooltip>
  //             )}

  //             {consentApprove && (
  //               <>
  //                 <Tooltip title="Approve Consent" arrow placement="top">
  //                   <MDButton onClick={() => updateConsent(row._id, "accept")}>
  //                     <CiCircleCheck style={{ color: "green" }} />
  //                   </MDButton>
  //                 </Tooltip>
  //                 <Tooltip title="Reject Consent" arrow placement="top">
  //                   <MDButton onClick={() => updateConsent(row._id, "reject")}>
  //                     <IoIosCloseCircleOutline style={{ color: "red" }} />
  //                   </MDButton>
  //                 </Tooltip>
  //               </>
  //             )}
  //           </>
  //         ) : row?.consent === "reject" ? (
  //           <span style={{ color: "red" }}>Rejected</span>
  //         ) : role === "physician" ? (
  //           <>
  //             <Tooltip title="Patient Details" arrow placement="top">
  //               <MDButton onClick={() => navigate(`/patient/${row._id}`)}>
  //                 <VisibilityIcon style={{ color: "grey" }}></VisibilityIcon>
  //               </MDButton>
  //             </Tooltip>
  //             <Tooltip title="Edit Patient" arrow placement="top">
  //               <MDButton onClick={() => handleEditOpen(row)}>
  //                 <IoCreate />
  //               </MDButton>
  //             </Tooltip>
  //             <Tooltip title="Delete Patient" arrow placement="top">
  //               <MDButton
  //                 onClick={() => {
  //                   setDeleteDialog(true);
  //                   setDeletePatientId(row._id);
  //                 }}
  //               >
  //                 <Icon color="error">delete</Icon>
  //               </MDButton>
  //             </Tooltip>
  //             <Tooltip title="Open Medical Report" arrow placement="top">
  //               <MDButton onClick={() => handleOpenReport(row._id)}>
  //                 <MedicalInformationIcon
  //                   style={{ color: "grey" }}
  //                 ></MedicalInformationIcon>
  //               </MDButton>
  //             </Tooltip>
  //             <Tooltip title="Open Medical History" arrow placement="top">
  //               <MDButton onClick={() => handleAddHistory(row._id)}>
  //                 <MdAssignmentAdd />
  //               </MDButton>
  //             </Tooltip>
  //             <Tooltip title="Export Medical History" arrow placement="top">
  //               <MDButton onClick={() => handledownloadExcel(row._id)}>
  //                 <ArrowDownwardIcon />
  //               </MDButton>
  //             </Tooltip>
  //           </>
  //         ) : (
  //           <>
  //             <Tooltip title="Open Medical Report" arrow placement="top">
  //               <MDButton onClick={() => handleOpenReport(row._id)}>
  //                 <VisibilityIcon style={{ color: "grey" }}></VisibilityIcon>
  //               </MDButton>
  //             </Tooltip>
  //             <Tooltip title="Download Medical Report" arrow placement="top">
  //               <MDButton onClick={() => handleDownloadReport(row._id)}>
  //                 <MdCloudDownload style={{ color: "grey" }}> </MdCloudDownload>
  //               </MDButton>
  //             </Tooltip>
  //           </>
  //         )}
  //       </MDBox>
  //     ),
  //   }));
  // };

  useEffect(() => {
    setTableData({ ...tableData, rows: renderRows() });
  }, [consentApprove]);

  console.log(tableData,'tableData');

  const renderRows = () => {
    return tableData.rows.map((row, index) => ({
      ...row,
      actions: (
        <MDBox display="flex" justifyContent="space-evenly">
          {row?.consent === "progress" ? (
            <>
              <span style={{ color: "#1a73e8", paddingTop: "8px" }}>
                In Progress
              </span>
              <></>
              {role === "physician" && (
                <Tooltip title="Print Consent Form" arrow placement="top">
                  <MDButton>
                    <FaPrint
                      onClick={() => {
                        reactToPrintFn();
                        setConsentApprove(true);
                        // alert("modal open")
                      }}
                    />
                    <div style={{ display: "none" }}>
                      <ConsentFormPrint contentREf={contentRef} />
                    </div>
                  </MDButton>
                </Tooltip>
              )}

              {consentApprove && (
                <>
                  <Tooltip title="Approve Consent" arrow placement="top">
                    <MDButton onClick={() => updateConsent(row._id, "accept")}>
                      <CiCircleCheck style={{ color: "green" }} />
                    </MDButton>
                  </Tooltip>
                  <Tooltip title="Reject Consent" arrow placement="top">
                    <MDButton onClick={() => updateConsent(row._id, "reject")}>
                      <IoIosCloseCircleOutline style={{ color: "red" }} />
                    </MDButton>
                  </Tooltip>
                </>
              )}
            </>
          ) : row?.consent === "reject" ? (
            <span style={{ color: "red" }}>Rejected</span>
          ) : role === "physician" ? (
            <>
              <Tooltip title="Patient Information Record" arrow placement="top">
                <MDButton onClick={() => navigate(`/patient/${row._id}`)}>
                  <VisibilityIcon style={{ color: "grey" }}></VisibilityIcon>
                </MDButton>
              </Tooltip>
              <Tooltip title="Edit Patient" arrow placement="top">
                <MDButton onClick={() => handleEditOpen(row)}>
                  <IoCreate />
                </MDButton>
              </Tooltip>
              <Tooltip title="Delete Patient" arrow placement="top">
                <MDButton
                  onClick={() => {
                    setDeleteDialog(true);
                    setDeletePatientId(row._id);
                  }}
                >
                  <Icon color="error">delete</Icon>
                </MDButton>
              </Tooltip>
              {/* <Tooltip title="Open Medical Report" arrow placement="top">
                <MDButton onClick={() => handleOpenReport(row._id)}>
                  <MedicalInformationIcon
                    style={{ color: "grey" }}
                  ></MedicalInformationIcon>
                </MDButton>
              </Tooltip>
              <Tooltip title="Open Medical History" arrow placement="top">
                <MDButton onClick={() => handleAddHistory(row._id)}>
                  <MdAssignmentAdd />
                </MDButton>
              </Tooltip> */}
              {/* <Tooltip title="Export Medical History" arrow placement="top">
                <MDButton onClick={() => handledownloadExcel(row._id)}>
                  <ArrowDownwardIcon />
                </MDButton>
              </Tooltip> */}
            </>
          ) : (
            <>
              <Tooltip title="Open Medical Report" arrow placement="top">
                <MDButton onClick={() => handleOpenReport(row._id)}>
                  <VisibilityIcon style={{ color: "grey" }}></VisibilityIcon>
                </MDButton>
              </Tooltip>
              <Tooltip title="Download Medical Report" arrow placement="top">
                <MDButton onClick={() => handleDownloadReport(row._id)}>
                  <MdCloudDownload style={{ color: "grey" }}> </MdCloudDownload>
                </MDButton>
              </Tooltip>
            </>
          )}
        </MDBox>
      ),
    }));
  };

  console.log(consentApprove, "consent");


  if (!token) {
    return <></>;
  }

  return (
    <DashboardLayout>
      {/* <MDSnackbar
        color="success"
        icon="check"
        title="Success"
        content="Operation completed successfully"
        open={successSB}
        onClose={() => setSuccessSB(false)}
        close={() => setSuccessSB(false)}
        bgWhite
      /> */}
      {/* <MDSnackbar
          color="error"
          icon="error"
          title="Error"
          content={responseError}
          open={errorSB}
          onClose={() => setErrorSB(false)}
          close={() => setErrorSB(false)}
          bgWhite
        /> */}
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="info"
                borderRadius="lg"
                coloredShadow="info"
                display="flex"
                justifyContent="space-between"
              >
                <MDTypography variant="h6" color="white">
                  Patients Table
                </MDTypography>
                {role === "physician" && (
                  <MDButton onClick={handleOpen}>
                    <Icon>add</Icon>Add Patient
                  </MDButton>
                )}
              </MDBox>
              <MDBox pt={3}>
                {isPatientLoading ? (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "30px",
                    }}
                  >
                    <CircularProgress />
                  </Box>
                ) : tableData?.rows?.length > 0 ? (
                  <>
                    {jwtDecode(localStorage.getItem("token")).user.role ===
                    "admin" ? (
                      <MDBox sx={{ width: 180, ml: 170 }}>
                        <FormControl fullWidth size="small">
                          <InputLabel id="view-select-label">
                            Select Hospital
                          </InputLabel>
                          <Select
                            labelId="view-select-label"
                            id="view-select"
                            value={selectedHospital}
                            label="Select Hospital"
                            style={{
                              paddingTop: "12px",
                              paddingBottom: "12px",
                            }}
                            onChange={(event) =>
                              handleHospitalChange(event.target.value)
                            }
                          >
                            {hospitals.map((h, index) => (
                              <MenuItem key={index} value={h}>
                                {" "}
                                {/* Use a unique key for each MenuItem */}
                                {h}{" "}
                                {/* Assuming `h` is the hospital name or some identifiable string */}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </MDBox>
                    ) : (
                      <MDBox sx={{ width: 180, ml: 160 }}>
                        <TextField
                          id="outlined-basic"
                          label="Search for Patients"
                          value={searchPatient}
                          onChange={(e  ) => handleChangeSearchPatient(e)}
                          variant="outlined"
                        />
                      </MDBox>
                    )}
                    <DataTable
                      table={seachActive ? searhedData:tableData}
                      isSorted={true}
                      entriesPerPage={5}
                      showTotalEntries={true}
                      noEndBorder
                    />
                  </>
                ) : (
                  <MDTypography
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      paddingBottom: "30px",
                    }}
                  >
                    No Patients Present
                  </MDTypography>
                )}
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <MDReportModal
        open={openPatientReportModal}
        handleClose={handleClosePatientReportModal}
        AddPatientReports={handleAddPatientReports}
        EditPatientReports={handleEditPatientReports}
        reportData={medicalReportData}
        isDisabled={role === "admin"}
      />

      <MDDelete
        deleteDialog={deleteDialog}
        handleDeleteDialog={handleDeleteDialog}
        handleDelete={handleDeletePatient}
      />

      <MDHistoryModal
        open={openPatientHistoryModal}
        handleClose={handleClosePatientHistoryModal}
        patientHistoryId={historyPatientId}
        patientHistory={historyPatient}
      />

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="add-modal-title"
        aria-describedby="add-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 5,
            padding: 6,
            width: 400,
            textAlign: "center",
          }}
        >
          <MDTypography variant="h6" mb={3}>
            Add Patient
          </MDTypography>
          <MDInput
            label="First Name"
            name="name"
            value={newPatientData.name?.trim() || ""}
            onChange={handleChange}
            fullWidth
            // error={!!formErrors.fname}
            helperText={formErrors.fname}
            FormHelperTextProps={{
              sx: {
                color: formErrors.fname ? "red" : "inherit",
              },
            }}
            sx={{ mb: 3 }}
          />
          <MDInput
            label="Last Name"
            name="lastname"
            value={newPatientData.lastname?.trim() || ""}
            onChange={handleChange}
            fullWidth
            // error={!!formErrors.lname}
            helperText={formErrors.lname}
            FormHelperTextProps={{
              sx: {
                color: formErrors.lname ? "red" : "inherit",
              },
            }}
            sx={{ mb: 3 }}
          />
          <MDInput
            label="Email"
            name="email"
            value={newPatientData.email?.trim() || ""}
            onChange={handleChange}
            fullWidth
            // error={!!formErrors.email}
            helperText={formErrors.email}
            FormHelperTextProps={{
              sx: {
                color: formErrors.email ? "red" : "inherit",
              },
            }}
            sx={{ mb: 3 }}
          />
          <div
            style={{ position: "relative", paddingBottom: "20px" }}
            className="editPatient"
          >
            <DatePicker
              selected={newPatientData.dob}
              onChange={handleDateChange}
              dateFormat="dd/MM/yyyy"
              maxDate={new Date()}
              placeholderText="dd/MM/yyyy"
              customInput={
                <TextField
                  label="Date of Birth"
                  fullWidth
                  helperText={formErrors.dob}
                  FormHelperTextProps={{
                    sx: {
                      color: formErrors.dob ? "red" : "inherit",
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <CalendarTodayIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              }
            />
          </div>
          {/* <MDInput
            label="Mobile Number"
            name="mobileNumber"
            value={newPatientData.mobileNumber || ""}
            onChange={handleChange}
            fullWidth
            error={!!formErrors.mobileNumber}
            helperText={formErrors.mobileNumber}
            sx={{ mb: 3 }}
          /> */}
          <MDInput
            label="City of Residence"
            name="address"
            value={newPatientData.address?.trim() || ""}
            onChange={handleChange}
            fullWidth
            // error={!!formErrors.address}
            helperText={formErrors.address}
            FormHelperTextProps={{
              sx: {
                color: formErrors.address ? "red" : "inherit",
              },
            }}
            sx={{ mb: 3 }}
          />
          <FormControl fullWidth sx={{ mb: 3 }}>
            <InputLabel>Gender</InputLabel>
            <Select
              label="Gender"
              name="gender"
              value={newPatientData.gender || ""}
              onChange={handleChange}
              IconComponent={() => <IoMdArrowDropdown />}
              sx={{ padding: "12px !important", textAlign: "left" }}
            >
              <MenuItem value="male">Male</MenuItem>
              <MenuItem value="female">Female</MenuItem>
              <MenuItem value="other">Other</MenuItem>
            </Select>
            {formErrors.gender && (
              <FormHelperText style={{ color: "red" }}>
                {formErrors.gender}
              </FormHelperText>
            )}
          </FormControl>

          {/* // Blood Group Dropdown */}
          {/* <FormControl fullWidth error={!!formErrors.bloodgroup} sx={{ mb: 3 }}>
            <InputLabel>Blood Group</InputLabel>
            <Select
              label="Blood Group"
              name="bloodgroup"
              value={newPatientData.bloodgroup || ""}
              onChange={handleChange}
              IconComponent={() => <IoMdArrowDropdown />}
              sx={{ padding: "12px !important", textAlign: "left" }}
            >
              <MenuItem value="A+">A+</MenuItem>
              <MenuItem value="A-">A-</MenuItem>
              <MenuItem value="B+">B+</MenuItem>
              <MenuItem value="B-">B-</MenuItem>
              <MenuItem value="AB+">AB+</MenuItem>
              <MenuItem value="AB-">AB-</MenuItem>
              <MenuItem value="O+">O+</MenuItem>
              <MenuItem value="O-">O-</MenuItem>
            </Select>
            {formErrors.bloodgroup && (
              <FormHelperText>{formErrors.bloodgroup}</FormHelperText>
            )}
          </FormControl> */}
          {/* <MDInput
            label="Amount"
            name="amount"
            value={newPatientData.amount || ""}
            onChange={handleChange}
            fullWidth
            error={!!formErrors.amount}
            helperText={formErrors.amount}
            sx={{ mb: 3 }}
          /> */}

          <MDButton onClick={handleAddPatient} color="info" fullWidth>
            {isAddPatientLoading ? (
              <div className="loader_wrapper">
                <CircularProgress style={{ height: "20px" }} />
              </div>
            ) : (
              "Add Patient"
            )}
          </MDButton>
        </Box>
      </Modal>

      <Modal
        open={editOpen}
        onClose={handleEditClose}
        aria-labelledby="edit-modal-title"
        aria-describedby="edit-modal-description"
        sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <Box
          sx={{
            backgroundColor: "background.paper",
            borderRadius: 2,
            boxShadow: 5,
            padding: 6,
            width: 400,
            textAlign: "center",
          }}
        >
          <MDTypography variant="h6" mb={3}>
            Edit Patient
          </MDTypography>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <MDInput
                label="Name"
                name="name"
                value={editPatientData.name?.trim() || ""}
                onChange={handleEditChange}
                fullWidth
                helperText={editFormErrors.fname}
                FormHelperTextProps={{
                  sx: {
                    color: editFormErrors.fname ? "red" : "inherit",
                  },
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <MDInput
                label="Last Name"
                name="lastname"
                value={editPatientData.lastname?.trim() || ""}
                onChange={handleEditChange}
                fullWidth
                helperText={editFormErrors.lname}
                FormHelperTextProps={{
                  sx: {
                    color: editFormErrors.lname ? "red" : "inherit",
                  },
                }}
              />
            </Grid>
            {/* <div style={{width:'180px'}}> */}
            <Grid item xs={12} className="editPatient">
              {/* <DatePicker
                selected={editPatientData.dob}
                onChange={(date) =>
                  setEditPatientData({ ...editPatientData, dob: date })
                }
                dateFormat="yyyy-MM-dd"
                placeholderText="Select DOB"
                // style={{width: "100%"}}
                maxDate={new Date()}
                customInput={
                  <MDInput
                    label="Date of Birth"
                    fullWidth
                    sx={{ mb: 2 }}
                  />
                  
                }
              /> */}

              <DatePicker
                selected={editPatientData.dob}
                onChange={(date) =>
                  setEditPatientData({ ...editPatientData, dob: date })
                }
                dateFormat="dd/MM/yyyy"
                maxDate={new Date()} // Restrict to past dates
                placeholderText="dd/MM/yyyy"
                customInput={
                  <TextField
                    label="Date of Birth"
                    fullWidth
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <CalendarTodayIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                }
              />
            </Grid>
            {/* <div style={{width:"100%", position: "relative" }}> */}
            {/* </div> */}
            {/* </div> */}
            {/* <Grid item xs={12}>
              <MDInput
                label="Mobile Number"
                name="mobileNumber"
                value={editPatientData.mobileNumber || ""}
                onChange={handleEditChange}
                fullWidth
                error={!!editFormErrors.mobileNumber}
                helperText={editFormErrors.mobileNumber}
              />
            </Grid> */}
            <Grid item xs={12}>
              <MDInput
                label="City of Residence"
                name="address"
                value={editPatientData.address?.trim() || ""}
                onChange={handleEditChange}
                fullWidth
                helperText={editFormErrors.address}
                FormHelperTextProps={{
                  sx: {
                    color: editFormErrors.address ? "red" : "inherit",
                  },
                }}
              />
            </Grid>
            <br />
            <br />
            <Grid item xs={12}>
              <FormControl fullWidth error={!!formErrors.gender} sx={{ mb: 3 }}>
                <InputLabel>Gender</InputLabel>
                <Select
                  label="Gender"
                  name="gender"
                  value={editPatientData.gender?.toLowerCase() || ""}
                  onChange={handleEditChange}
                  IconComponent={() => <IoMdArrowDropdown />}
                  sx={{ padding: "12px !important", textAlign: "left" }}
                >
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
                {formErrors.gender && (
                  <FormHelperText>{formErrors.gender}</FormHelperText>
                )}
              </FormControl>
            </Grid>

            {/* // Blood Group Dropdown */}
            {/* <Grid item xs={12}>
              <FormControl
                fullWidth
                error={!!formErrors.bloodgroup}
                sx={{ mb: 3 }}
              >
                <InputLabel>Blood Group</InputLabel>
                <Select
                  label="Blood Group"
                  name="bloodgroup"
                  value={editPatientData.bloodgroup || ""}
                  onChange={handleEditChange}
                  IconComponent={() => <IoMdArrowDropdown />}
                  sx={{ padding: "12px !important", textAlign: "left" }}
                >
                  <MenuItem value="A+">A+</MenuItem>
                  <MenuItem value="A-">A-</MenuItem>
                  <MenuItem value="B+">B+</MenuItem>
                  <MenuItem value="B-">B-</MenuItem>
                  <MenuItem value="AB+">AB+</MenuItem>
                  <MenuItem value="AB-">AB-</MenuItem>
                  <MenuItem value="O+">O+</MenuItem>
                  <MenuItem value="O-">O-</MenuItem>
                </Select>
                {formErrors.bloodgroup && (
                  <FormHelperText>{formErrors.bloodgroup}</FormHelperText>
                )}
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={12}>
              <MDInput
                label="Amount"
                name="amount"
                value={editPatientData.amount || ""}
                onChange={handleEditChange}
                fullWidth
                error={!!editFormErrors.amount}
                helperText={editFormErrors.amount}
              />
            </Grid> */}
            <Grid item xs={12}>
              <MDButton onClick={handleEditPatient} color="info" fullWidth>
                Edit Patient
              </MDButton>
            </Grid>
          </Grid>
        </Box>
      </Modal>
    </DashboardLayout>
  );
};

export default PatientsTables;
