import React, { useState, useEffect, useContext } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import { AuthContext } from "context";

const SNOT22Dialog = ({ onSave, values, isDisabled }) => {
  const initialSnotValues = {
    needToBlowNose: 0,
    sneezing: 0,
    runnyNose: 0,
    cough: 0,
    postNasalDischarge: 0,
    thickNasalDischarge: 0,
    earFullness: 0,
    dizziness: 0,
    earPainPressure: 0,
    facialPainPressure: 0,
    difficultyFallingAsleep: 0,
    wakingUpAtNight: 0,
    lackOfGoodNightsSleep: 0,
    wakingUpTired: 0,
    fatigueDuringDay: 0,
    reducedProductivity: 0,
    reducedConcentration: 0,
    frustratedIrritable: 0,
    sad: 0,
    embarrassed: 0,
    senseOfTasteSmell: 0,
    nasalBlockageCongestion: 0,
  };

  const {
    userData: { role, token },
  } = useContext(AuthContext);
  const [snotValues, setSnotValues] = useState(initialSnotValues);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (values) {
      // Only update the individual field values, not the total score
      const newValues = Object.keys(initialSnotValues).reduce((acc, key) => {
        acc[key] = values[key] || 0;
        return acc;
      }, {});
      setSnotValues(newValues);
    }
  }, [values]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (key, value) => {
    let validatedValue = Math.min(Math.max(parseInt(value) || 0, 0), 5);
    setSnotValues(prevValues => ({
      ...prevValues,
      [key]: validatedValue
    }));
  };

  const calculateTotalScore = () => {
    return Object.values(snotValues).reduce((acc, curr) => acc + Number(curr), 0);
  };

  const handleSave = () => {
    const totalScore = calculateTotalScore();
    const updatedValues = {
      ...snotValues,
      totalSNOTScore: totalScore
    };
    onSave(updatedValues);
    setOpen(false);
  };

  // Function to get severity description
  const getSeverityDescription = (value) => {
    switch (Number(value)) {
      case 0:
        return "No problem";
      case 1:
        return "Very mild problem";
      case 2:
        return "Mild problem";
      case 3:
        return "Moderate problem";
      case 4:
        return "Severe problem";
      case 5:
        return "Problem as bad as it can be";
      default:
        return "";
    }
  };

  return (
    <div>
      <Button
        variant="outlined"
        color="primary"
        onClick={handleClickOpen}
        style={{ color: "black" }}
      >
        {values ? "Edit SNOT-22 Scale" : "SNOT-22 Scale"}
      </Button>

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          {values ? "Edit SNOT-22 Assessment" : "New SNOT-22 Assessment"}
        </DialogTitle>
        <DialogContent style={{ paddingTop: "8px" }}>
          <Grid container spacing={2}>
            {Object.entries(initialSnotValues).map(([key, _], index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                  type="number"
                  InputProps={{ inputProps: { min: 0, max: 5 } }}
                  value={snotValues[key]}
                  onChange={(e) => handleChange(key, e.target.value)}
                  fullWidth
                  disabled={isDisabled}
                />
                <div style={{ marginTop: "5px" }}>
                  <span>{getSeverityDescription(snotValues[key])}</span>
                </div>
              </Grid>
            ))}
          </Grid>

          <div style={{ marginTop: "20px" }}>
            <h4>Current Total SNOT-22 Score: {calculateTotalScore()}</h4>
          </div>
        </DialogContent>
        {role === "physician" &&
          new URL(window.location.href).pathname !==
          "/hospital-wide-data" && (
            <Button onClick={handleSave} color="primary">
              {values ? "Update" : "Save"}
            </Button>
          )}
      </Dialog>
    </div>
  );
};

export default SNOT22Dialog;
